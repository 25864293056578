import * as t from 'ts-schema';
import { registerQuery } from 'data-fetching-client';
import { getAcademyCertifications } from '../../clients/credentialsClient';
const providerCertificationsResponseSchema = t.object({
  results: t.array(t.object({
    id: t.number,
    internalName: t.string,
    enabled: t.boolean,
    icon: t.string,
    topic: t.string,
    granteeEntity: t.string,
    type: t.string,
    slug: t.optional(t.string),
    metadata: t.array(t.object({
      language: t.string,
      title: t.string,
      description: t.string
    }))
  })),
  total: t.number
});
function getProviderCertifications() {
  return getAcademyCertifications();
}
export const GET_PROVIDER_CERTIFICATIONS = registerQuery({
  fieldName: 'certifications',
  args: [],
  fetcher: async () => {
    const response = await getProviderCertifications();
    return t.assert(providerCertificationsResponseSchema, response);
  }
});