export const getThemeEventSelectorId = id => `marketplace-mirrored__theme__${id}`;
export const getModuleEventSelectorId = id => `marketplace-mirrored__module__${id}`;
export const getAssetEventSelectorId = (id, offeringType) => {
  switch (offeringType) {
    case 'THEME':
      return getThemeEventSelectorId(id);
    case 'MODULE':
      return getModuleEventSelectorId(id);
    default:
      return getThemeEventSelectorId(id);
  }
};