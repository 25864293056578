module.exports = {
  "pageView": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "min_overall_rating": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": "array",
      "embed": "boolean",
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "nextStep": {
        "type": "string",
        "isOptional": true
      },
      "screen": "string",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "action": "string",
      "category": {
        "type": "string",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": {
        "type": "string",
        "isOptional": true
      },
      "sessionUrlParams": "array",
      "embed": "boolean",
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-filter-view": {
    "name": "filter",
    "class": "view",
    "properties": {
      "screen": [
        "storefront"
      ],
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "nextStep": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "module",
        "theme"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "pageType": {
        "type": "array",
        "isOptional": true
      },
      "feature": {
        "type": "array",
        "isOptional": true
      },
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "minOverallRating": {
        "type": "number",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-change-filter": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": [
        "storefront"
      ],
      "action": [
        "change filter"
      ],
      "filter": "string",
      "value": {
        "isOptional": true,
        "type": "array"
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "nextStep": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "module",
        "theme"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "pageType": {
        "type": "array",
        "isOptional": true
      },
      "feature": {
        "type": "array",
        "isOptional": true
      },
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "minOverallRating": {
        "type": "number",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "storefront-close-filter-tag": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "screen": [
        "storefront"
      ],
      "action": [
        "close filter tag"
      ],
      "filter": "string",
      "value": {
        "isOptional": true,
        "type": "array"
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "nextStep": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "limit": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "module",
        "theme"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "category": {
        "type": "string",
        "isOptional": true
      },
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "pageType": {
        "type": "array",
        "isOptional": true
      },
      "feature": {
        "type": "array",
        "isOptional": true
      },
      "paymentType": {
        "type": "string",
        "isOptional": true
      },
      "minOverallRating": {
        "type": "number",
        "isOptional": true
      },
      "tags": {
        "type": "array",
        "isOptional": true
      },
      "sortField": {
        "type": "string",
        "isOptional": true
      },
      "search": {
        "type": "string",
        "isOptional": true
      },
      "searchLength": {
        "type": "number",
        "isOptional": true
      },
      "pageNumber": {
        "type": "number",
        "isOptional": true
      },
      "totalResults": {
        "type": "number",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "details-pageview": {
    "name": "pageview",
    "class": "view",
    "properties": {
      "min_overall_rating": {
        "type": "string",
        "isOptional": true
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "theme",
        "module"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "screen": [
        "details",
        "details preview",
        "details admin preview"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "slug": "string",
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "hasFeatures": {
        "type": "array",
        "isOptional": true
      },
      "homemade": "boolean",
      "offeringId": "number",
      "orderTotal": "number",
      "isCompatible": {
        "type": "boolean",
        "isOptional": true
      },
      "isInstallableByUser": {
        "type": "boolean",
        "isOptional": true
      },
      "isNotInstallableReason": {
        "type": "string",
        "isOptional": true
      },
      "missingFeatures": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "details-interaction": {
    "name": "interaction",
    "class": "interaction",
    "properties": {
      "nextStep": {
        "type": "string",
        "isOptional": true
      },
      "action": [
        "click breadcrumb link",
        "click provider link",
        "click review count",
        "click tab navigation",
        "click install button",
        "click preview button",
        "click setup guide link",
        "click share link",
        "click version history link",
        "click filter tag",
        "click expand tags link",
        "click user guide link",
        "click support website link",
        "click community link",
        "click case study link",
        "click copy email address",
        "click live chat link",
        "click facebook link",
        "click privacy policy link",
        "click terms link",
        "click other privacy policy link",
        "click main image",
        "click image thumbnail",
        "click show all images",
        "complete video",
        "start video",
        "click back link",
        "click screenshot image",
        "click carousel previous button",
        "click carousel close button",
        "click carousel see all images button",
        "click carousel next button",
        "close feature image lightbox",
        "click feature image",
        "expand contents",
        "collapse contents",
        "click client example",
        "click see all link",
        "click result item",
        "select payment type",
        "click install confirm button",
        "close version history panel",
        "set preview device",
        "close preview modal",
        "click preview link",
        "click copy preview link",
        "open review wizard",
        "change review wizard step",
        "dismiss review wizard",
        "click report this asset link",
        "click see all compatible plans popover",
        "click account plan link",
        "click tiers tooltip pricing link",
        "click public pricing link",
        "click partial plan compatibility",
        "click sign in cta button",
        "change version history page",
        "click product privacy policy link",
        "click compatibility modal pricing link"
      ],
      "filter": {
        "type": "string",
        "isOptional": true
      },
      "itemIndex": {
        "type": "string",
        "isOptional": true
      },
      "section": {
        "type": "string",
        "isOptional": true
      },
      "value": {
        "type": "string",
        "isOptional": true
      },
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "theme",
        "module"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "screen": [
        "details",
        "details preview",
        "details admin preview"
      ],
      "subscreen": {
        "type": "string",
        "isOptional": true
      },
      "slug": "string",
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "hasFeatures": {
        "type": "array",
        "isOptional": true
      },
      "homemade": "boolean",
      "offeringId": "number",
      "orderTotal": "number",
      "isCompatible": {
        "type": "boolean",
        "isOptional": true
      },
      "isInstallableByUser": {
        "type": "boolean",
        "isOptional": true
      },
      "isNotInstallableReason": {
        "type": "string",
        "isOptional": true
      },
      "missingFeatures": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  },
  "details-click-install": {
    "name": "click install asset cta",
    "class": "usage",
    "properties": {
      "subscreen": [
        "order summary modal"
      ],
      "paymentType": [
        "free",
        "stripe",
        "paypal"
      ],
      "value": "number",
      "totalCount": {
        "type": "string",
        "isOptional": true
      },
      "allFilters": {
        "type": "string",
        "isOptional": true
      },
      "inAppOrPublic": [
        "in-app",
        "public"
      ],
      "type": [
        "theme",
        "module"
      ],
      "sessionUrlParams": "array",
      "embed": "boolean",
      "screen": [
        "details",
        "details preview",
        "details admin preview"
      ],
      "slug": "string",
      "industry": {
        "type": "array",
        "isOptional": true
      },
      "hasFeatures": {
        "type": "array",
        "isOptional": true
      },
      "homemade": "boolean",
      "offeringId": "number",
      "orderTotal": "number",
      "isCompatible": {
        "type": "boolean",
        "isOptional": true
      },
      "isInstallableByUser": {
        "type": "boolean",
        "isOptional": true
      },
      "isNotInstallableReason": {
        "type": "string",
        "isOptional": true
      },
      "missingFeatures": {
        "type": "array",
        "isOptional": true
      }
    },
    "namespace": "marketplace",
    "version": "1"
  }
};