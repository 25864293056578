import { configureStore } from '@reduxjs/toolkit';

/* @ts-expect-error module is untyped */
import NotificationMiddleware from 'ReduxMessenger/middleware/NotificationMiddleware';
import rootReducer from '../reducers';
export function setupStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      /* TODO: allow serializableCheck - depends on removing Immutable.Map from
       * certifications reducer */
      return getDefaultMiddleware({
        serializableCheck: false
      }).concat(NotificationMiddleware);
    },
    preloadedState
  });
}
const store = setupStore();
export default store;