import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["features", "offering", "pricing", "provider", "reviewSummary", "screenshots", "support"],
  _excluded2 = ["features", "offering", "pricing", "provider", "reviewSummary", "screenshots", "support"];
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
import Pricing from 'marketplace-ui-common/records/Pricing';
import ReviewSummary from 'marketplace-ui-reviews/records/ReviewSummary';
import Screenshot from 'marketplace-ui-common/records/Screenshot';
import { assetBaseDetailsResponseDefaults, AssetOffering } from './AssetBaseDetailsResponse';
import AssetFeature from './AssetFeature';
import AssetProviderDetailsResponse from './AssetProviderDetailsResponse';
import SupportInfo from './SupportInfo';
export default class ModuleDetailsResponse extends createImmutableObjectClass(assetBaseDetailsResponseDefaults, {
  fromJSON: _ref => {
    let {
        features,
        offering,
        pricing,
        provider,
        reviewSummary,
        screenshots,
        support
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return cleanObject(Object.assign({
      features: features.map(feature => new AssetFeature(feature)),
      offering: new AssetOffering(offering),
      pricing: new Pricing(pricing),
      provider: new AssetProviderDetailsResponse(provider),
      reviewSummary: new ReviewSummary(reviewSummary),
      screenshots: screenshots.map(screenshot => new Screenshot(screenshot)),
      support: support ? new SupportInfo(support) : null
    }, rest));
  },
  toJSON: _ref2 => {
    let {
        features,
        offering,
        pricing,
        provider,
        reviewSummary,
        screenshots,
        support
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      features: features.map(feature => feature.toJSON()),
      offering: offering.toJSON(),
      pricing: pricing.toJSON(),
      provider: provider.toJSON(),
      reviewSummary: reviewSummary.toJSON(),
      screenshots: screenshots.map(screenshot => screenshot.toJSON()),
      support: support ? support.toJSON() : null
    }, rest);
  }
}) {}