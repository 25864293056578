import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AssetBusinessTypeUrls from '../../constants/urls/AssetBusinessType';
import AssetPageFeatureUrls from '../../constants/urls/AssetPageFeature';
import AssetPageTypeUrls from '../../constants/urls/AssetPageType';
import ModuleCategoryTypeUrls from '../../modules/urls/ModuleCategory';

// find key of record from value
function getParamEnumValue(record, value) {
  if (!value) return null;
  const entry = Object.entries(record).find(([__, v]) => v === value);
  return entry ? entry[0] : null;
}
export function useStorefrontRouteParams(offeringType) {
  const params = useParams();

  // Themes
  const businessType = useMemo(() => getParamEnumValue(AssetBusinessTypeUrls, params.businessType), [params.businessType]);
  const pageType = useMemo(() => getParamEnumValue(AssetPageTypeUrls, params.pageType), [params.pageType]);
  const pageFeature = useMemo(() => getParamEnumValue(AssetPageFeatureUrls, params.pageFeature), [params.pageFeature]);

  // Modules
  const category = useMemo(() => getParamEnumValue(ModuleCategoryTypeUrls, params.category), [params.category]);
  if (offeringType === 'THEME') {
    return Object.assign({}, businessType && {
      businessType
    }, pageType && {
      pageType
    }, pageFeature && {
      pageFeature
    }, {
      pageNumber: params.pageNumber ? parseInt(params.pageNumber, 10) : 1
    });
  } else {
    return Object.assign({}, category && {
      category
    }, {
      pageNumber: params.pageNumber ? parseInt(params.pageNumber, 10) : 1
    });
  }
}