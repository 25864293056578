'use es6';

import createPublicI18nProvider from 'I18n/init/providers/createPublicI18nProvider';
import getLang from 'I18n/utils/getLang';
import noAuthApiClient from 'hub-http/clients/noAuthApiClient';
import BaseApp from 'marketplace-assets-base/App';
import { setApiClient } from 'marketplace-assets-base/clients/apiClient';
import { setEcosystemApiBase } from 'marketplace-assets-base/clients/ecosystemClient';
import { setQualityApiBase } from 'marketplace-assets-base/clients/qualityClient';
import { PUBLIC } from 'marketplace-assets-base/constants/AppContext';
import { getPublicRootUrl } from 'marketplace-assets-base/utils/urls';
import { getUsageTrackerConfig } from 'marketplace-assets-base/utils/usageTracker';
import quickFetch from 'quick-fetch';
import { createTracker } from 'usage-tracker-public';
import rhumbConfig from '../rhumb-config.yaml';
import AppContainer from './AppContainer';
let userEmailPromise;
function maybeGetUserEmail() {
  if (!userEmailPromise) {
    const earlyPublicAuthCheckRequest = quickFetch.getRequestStateByName('public-auth-check');
    const fetchPublicAuth = () => noAuthApiClient.get('/cookie-echo/v1/user');
    let resultPromise;
    if (earlyPublicAuthCheckRequest) {
      resultPromise = new Promise((resolve, reject) => {
        earlyPublicAuthCheckRequest.whenFinished(resp => {
          resolve(resp);
          quickFetch.removeEarlyRequest('public-auth-check');
        });
        earlyPublicAuthCheckRequest.onError(() => {
          fetchPublicAuth().then(resp => resolve(resp)).catch(error => reject(error));
          quickFetch.removeEarlyRequest('public-auth-check');
        });
      });
    } else {
      resultPromise = fetchPublicAuth();
    }
    userEmailPromise = resultPromise.then(resp => resp.email);
  }
  return userEmailPromise;
}
export default class App extends BaseApp {
  initialize() {
    setQualityApiBase('marketplace/quality/customer/v1/public');
    setEcosystemApiBase('ecosystem/public/v1');
    setApiClient(noAuthApiClient);
    this.setUsageTracker();
    const langAvailable = this.setupI18n();
    return langAvailable;
  }
  setupI18n() {
    const i18nProvider = createPublicI18nProvider({
      manuallySetLocale: true
    });
    i18nProvider.setLocale({
      locale: window.I18N_MANUAL_LANG || 'en',
      langEnabled: true
    });
    return this.registerI18n(i18nProvider);
  }
  setUsageTracker() {
    const usageTrackerConfig = getUsageTrackerConfig({
      inAppOrPublic: 'public'
    });
    usageTrackerConfig.isExternalHost = false;
    usageTrackerConfig.properties = Object.assign({}, usageTrackerConfig.properties, {
      email: () => maybeGetUserEmail().catch(() => null)
    });
    this.usageTracker = createTracker(usageTrackerConfig);
  }
  getAppContext() {
    return PUBLIC;
  }
  getAppName() {
    return 'marketplace-assets-public-ui';
  }
  getAppComponent() {
    return AppContainer;
  }
  getRootUrl() {
    return getPublicRootUrl(getLang());
  }
  getRhumbConfig() {
    return rhumbConfig;
  }
}