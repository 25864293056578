export const SEARCH = 'search';
export const OFFERING_IDS = 'offeringIds';
export const PLAN_TYPE = 'planType';
export const PRICE_RANGE = 'priceRange';
export const MIN_OVERALL_RATING = 'minOverallRating';
export const HAS_FREE_SUPPORT = 'hasFreeSupport';
export const SORT_FIELDS = 'sortFields';
export const LIMIT = 'limit';
export const OFFERING_TYPES = 'offeringTypes';
export const OFFSET = 'offset';
export const MIN_REVIEW_COUNT = 'minReviewCount';
export const PROVIDERS = 'providers';
export const TIERS = 'tiers';
export const PORTAL_COMPATIBLE = 'portalCompatible';

// TODO finalize
export const TAGS = 'tags';