import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["contents"],
  _excluded2 = ["contents"];
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
import { assetContentsResponseDefaults } from '../../details/records/AssetContentsResponse';
import ThemeContentItem from './ThemeContentItem';
const themeContentsResponseDefaults = Object.assign({}, assetContentsResponseDefaults, {
  contents: []
});
export default class ThemeContentsResponse extends createImmutableObjectClass(themeContentsResponseDefaults, {
  fromJSON: _ref => {
    let {
        contents
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return cleanObject(Object.assign({
      contents: contents.map(content => new ThemeContentItem(content))
    }, rest));
  },
  toJSON: _ref2 => {
    let {
        contents
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      contents: contents.map(content => content.toJSON())
    }, rest);
  }
}) {}