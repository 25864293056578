export const BLOG_ACCESS = 'blog-access';
export const CALLS_TO_ACTION_READ = 'calls-to-action-read';
export const CMS_MULTI_LANGUAGE = 'cms-multi-language';
export const CONVERSATIONS_ACCESS = 'conversations-access';
export const CTA_ACCESS = 'cta-access';
export const CUSTOMER_FEEDBACK_ACCESS = 'customer-feedback-access';
export const EMAIL_CORE_API_ACCESS = 'email-core-api-access';
export const FILE_MANAGER_ACCESS = 'file-manager-access';
export const FORMS_ACCESS = 'forms-access';
export const HUBDB_PAGE_TEMPLATE_ACCESS = 'hubdb-page-template-access';
export const LANDINGPAGES_ACCESS = 'landingpages-access';
export const LEAD_FLOWS_ACCESS = 'lead-flows-access';
export const MARKETING_CONTENT_RESTRICTED_WRITE = 'marketing-content-restricted-write';
export const MARKETING_VIDEO = 'marketing-video';
export const PAYMENT_LINKS_READ = 'payment-links-read';
export const PUBLIC_CONTENT = 'public-content';
export const SMARTCONTENT = 'smartcontent';
export const SOCIAL_ACCESS = 'social-access';
export const TOPIC_CLUSTERS_ACCESS = 'topic-clusters-access';