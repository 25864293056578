import { ASSET_QUERY_FILTER_ORDER } from '../../constants/AssetQuery/AssetQuery';
import { isThemeQuery } from '../isAssetType';
export function toFilterTags(query) {
  let tags = [];
  if (isThemeQuery(query)) {
    const {
      businessTypes,
      pageTypes,
      pageFeatures
    } = query;
    tags = tags.concat(toFieldTags('businessTypes', businessTypes));
    tags = tags.concat(toFieldTags('pageTypes', pageTypes));
    tags = tags.concat(toFieldTags('pageFeatures', pageFeatures));
  } else {
    tags = tags.concat(toFieldTags('contentTypes', query.contentTypes));
    tags = tags.concat(toFieldTags('categories', query.categories));
  }
  for (const key of ASSET_QUERY_FILTER_ORDER) {
    tags = tags.concat(toFieldTags(key, query[key]));
  }
  return tags;
}
function toFieldTags(field, value) {
  if (!value) return [];
  return Array.isArray(value) ? value.map(val => ({
    name: field,
    value: val
  })) : [{
    name: field,
    value
  }];
}