module.exports = {
  "/": {
    "name": "root-redirect"
  },
  "/website": {
    "name": "storefront-home",
    "success": {
      "storefront home loaded": [
        "STOREFRONT_HOME_LOAD"
      ],
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/website/page/:pageNumber": {
    "name": "storefront-home-page",
    "success": {
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/website/categories": {
    "name": "all-categories",
    "success": {
      "all categories loaded": [
        "THEMES_ALL_BUSINESS_TYPES_LOAD"
      ]
    }
  },
  "/website/all": {
    "name": "all-storefront",
    "success": {
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/website/themes/all-business-types": {
    "name": "themes-all-business-types-redirect"
  },
  "/website/themes/:slug": {
    "name": "theme-details-redirect"
  },
  "/website/:slugOrCategory": {
    "name": "theme-details-or-category",
    "success": {
      "theme details loaded": [
        "THEME_DETAILS_LOAD"
      ],
      "theme details preview forbidden error": [
        "THEME_DETAILS_FORBIDDEN_ERROR_LOAD"
      ],
      "theme details 404": [
        "THEME_DETAILS_403_LOAD"
      ],
      "theme details 403": [
        "THEME_DETAILS_404_LOAD"
      ],
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    },
    "error": [
      "THEME_DETAILS_ERROR_LOAD"
    ]
  },
  "/website/:category/page/:pageNumber": {
    "name": "storefront-category-page",
    "success": {
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/website/:category/:subcategory": {
    "name": "storefront-category-subcategory",
    "success": {
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/website/:category/:subcategory/page/:pageNumber": {
    "name": "storefront-category-subcategory-page",
    "success": {
      "themes loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/modules": {
    "name": "modules-home",
    "success": {
      "modules loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/modules/page/:pageNumber": {
    "name": "all-modules-storefront-page",
    "success": {
      "modules loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/modules/:slugOrCategory/page/:pageNumber": {
    "name": "modules-storefront-category-page",
    "success": {
      "modules loaded": [
        "ASSETS_LOAD"
      ]
    }
  },
  "/modules/:slugOrCategory": {
    "name": "module-details-or-category",
    "success": {
      "module details loaded": [
        "MODULE_DETAILS_LOAD"
      ],
      "module details preview forbidden error": [
        "MODULE_DETAILS_FORBIDDEN_ERROR_LOAD"
      ],
      "module details 403": [
        "MODULE_DETAILS_403_LOAD"
      ],
      "module details 404": [
        "MODULE_DETAILS_404_LOAD"
      ],
      "modules loaded": [
        "ASSETS_LOAD"
      ]
    },
    "error": [
      "MODULE_DETAILS_ERROR_LOAD"
    ]
  },
  "/templates": {
    "name": "templates-home",
    "success": {
      "templates loaded": [
        "TEMPLATES_LOAD"
      ]
    }
  },
  "/templates/featured": {
    "name": "templates-featured",
    "success": {
      "collections loaded": [
        "FEATURED_THEMES_LOAD"
      ]
    }
  },
  "/templates/search": {
    "name": "templates-search",
    "success": {
      "search results loaded": [
        "ASSETS_LOAD"
      ]
    }
  }
};