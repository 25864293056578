import enviro from 'enviro';
import { Flow } from 'signup-constants/Flow';
import { stringify } from 'hub-http/helpers/params';
import { getFullUrl } from 'hubspot-url-utils';
import { na1 } from 'hubspot-url-utils/hublets';
import getLang from 'I18n/utils/getLang';
import PortalIdParser from 'PortalIdParser';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
const isQa = () => enviro.isQa();
const getRootPath = () => '/marketplace';
export const getRootUrl = () => {
  return `/ecosystem/${PortalIdParser.get()}${getRootPath()}`;
};
export const getPublicRootUrl = lang => {
  const publicRoot = getRootPath();
  switch (lang) {
    case 'pt':
    case 'pt-br':
      return `/pt${publicRoot}`;
    case 'ja':
    case 'jp':
      return `/ja${publicRoot}`;
    case 'de':
    case 'es':
    case 'fr':
      return `/${lang}${publicRoot}`;
    default:
      return publicRoot;
  }
};
export const getModuleMarketplaceUrl = (appContext, slug) => {
  let url;
  if (appContext === 'app') {
    url = `${getFullUrl('app', {
      hubletOverride: na1
    })}/ecosystem/${PortalIdParser.get()}/marketplace/modules/${slug}`;
  } else {
    const lang = getLang();
    url = `${getFullUrl('ecosystem', {
      hubletOverride: 'na1'
    })}${getPublicRootUrl(lang)}/modules/${slug}`;
  }
  return url;
};
export const getThemeMarketplaceUrl = (appContext, slug) => {
  let urlRoot;
  if (appContext === 'app') {
    urlRoot = `${getFullUrl('app')}/ecosystem/${PortalIdParser.get()}`;
  } else {
    urlRoot = getFullUrl('ecosystem', {
      hubletOverride: 'na1'
    });
    const lang = getLang();
    switch (lang) {
      case 'de':
      case 'es':
      case 'fr':
      case 'ja':
      case 'pt':
        {
          urlRoot += `/${lang}`;
          break;
        }
      default:
        break;
    }
  }
  return `${urlRoot}/marketplace/website/${slug}`;
};
export const getTemplateMarketplaceUrl = (appContext, pathname) => {
  let url;
  if (appContext === 'app') {
    url = `${getFullUrl('app', {
      hubletOverride: na1
    })}/ecosystem/${PortalIdParser.get()}/marketplace/templates/${pathname}`;
  } else {
    const lang = getLang();
    url = `${getFullUrl('ecosystem', {
      hubletOverride: 'na1'
    })}${getPublicRootUrl(lang)}/templates/${pathname}`;
  }
  return url;
};
export const getDownloadedAssetsUrl = (isPublic = false, offeringType = 'THEME') => {
  if (isPublic) {
    return `${getFullUrl('app', {
      hubletOverride: na1
    })}/l/marketplace-settings/downloads/${offeringType === 'MODULE' ? 'modules' : 'theme'}`;
  }
  return `${getFullUrl('app')}/marketplace-settings/${PortalIdParser.get()}/downloads/${offeringType === 'MODULE' ? 'modules' : 'theme'}`;
};
export const getDownloadedAssetsSuccessUrl = (params, offeringType) => `${getDownloadedAssetsUrl(false, offeringType)}?${stringify(params)}`;
export const getDownloadedAssetsUpdateUrl = (offeringId, offeringType) => `${getDownloadedAssetsUrl(false, offeringType)}?updateOfferingId=${offeringId}`;
export const getCmsHubPricingUrl = () => 'https://www.hubspot.com/pricing/cms';
export const getMarketingHubPricingUrl = () => 'https://www.hubspot.com/pricing/marketing';
const getCmsFreeSignupQuery = theme => {
  const query = {
    intent: 'marketplaceTheme'
  };
  if (theme) {
    query.theme = theme;
  }
  return query;
};
export const getCmsFreeSignupUrl = theme => {
  return new SignupUrlGenerator(Flow.CmsFree, {
    env: isQa() ? 'qa' : 'prod',
    query: getCmsFreeSignupQuery(theme)
  }).getUrl();
};
export const getCmsFreeLoginSignupParams = theme => {
  return JSON.stringify([Flow.CmsFree, {
    query: getCmsFreeSignupQuery(theme)
  }]);
};
export const getAppShortlink = ({
  pathname = '',
  query = {},
  theme
} = {}, includeSignupParams = true) => {
  const signupParams = getCmsFreeLoginSignupParams(theme);
  const params = stringify(Object.assign({}, query, {
    signupParams: includeSignupParams ? signupParams : undefined
  }));
  return `${getFullUrl('app', {
    hubletOverride: 'na1'
  })}/l/ecosystem/marketplace${pathname}${params ? `?${params}` : ''}`;
};