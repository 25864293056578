import { debug } from './env';
function debugLog(...args) {
  if (debug('hubsParams')) {
    console.log('[utils/hubsParams]', ...args);
  }
}
export function appendHubsParams({
  forceParamUpdate = true,
  overrideExceptions
} = {}) {
  const {
    _appendHubsParams
  } = window._hsMktgAnalytics || {};
  if (_appendHubsParams) {
    _appendHubsParams({
      forceParamUpdate,
      overrideExceptions
    });
    debugLog('appending hubs params');
  } else {
    debugLog('would append hubs params');
  }
}