import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import app from './app';
/* @ts-expect-error module is untyped */
import auth from './auth';
/* @ts-expect-error module is untyped */
import notifications from 'ReduxMessenger/reducers/Notifications';
/* @ts-expect-error module is untyped */
import reviews from './reviews';
/* @ts-expect-error module is untyped */
import storefront from './storefront';
import themeDetails from './themeDetails';
const rootReducer = combineReducers({
  app,
  auth,
  notifications,
  reviews,
  storefront,
  themeDetails
});
export function useAppDispatch() {
  return useDispatch();
}
export default rootReducer;