'use es6';

import { getParsedTrackingParams } from 'marketplace-ui-common/utils/trackingParams';
import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
const getSessionUrlParams = () => {
  const trackingParams = getParsedTrackingParams();
  return Object.keys(trackingParams).reduce((final, key) => {
    const value = trackingParams[key];
    final.push(`${key}=${value}`);
    return final;
  }, []);
};
export const getUsageTrackerConfig = ({
  embed = false,
  inAppOrPublic = 'in-app'
} = {}) => ({
  events,
  properties: {
    namespace: 'marketplace',
    embed,
    inAppOrPublic,
    sessionUrlParams: getSessionUrlParams()
  },
  lastKnownEventProperties: ['screen']
});
export const createUsageTracker = properties => {
  const config = getUsageTrackerConfig(properties);
  return createTracker(config);
};