import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import produce from 'immer';
import isEqual from 'hs-lodash/isEqual';
import omit from 'hs-lodash/omit';
import { uniqueId } from 'UIComponents/utils/underscore';
export default class Screenshot extends createImmutableObjectClass({
  imageUrl: '',
  altText: '',
  id: ''
}, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return Object.assign({}, rest, {
      id: uniqueId('screenshot-')
    });
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {
  // 'id' is used as a unique key that persists as the record updates, for ListTransition
  equals(screenshot) {
    return isEqual(omit(this, ['id']), omit(screenshot, ['id']));
  }
  updateObject(updates) {
    return produce(this, draftScreenshot => {
      Object.entries(updates).forEach(([key, value]) => {
        draftScreenshot.set(key, value);
      });
    });
  }
}