import { useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

// borrowed from https://github.com/ReactTraining/react-router/blob/2d3c68b337347abd1291ea53f9ab8cb44a8a1da8/packages/react-router-dom/modules/NavLink.js#L24
const escapePath = path => path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');
export function useIsActiveRoute(to, exact, escape = true) {
  const computedPath = useMemo(() => {
    const path = typeof to === 'object' ? to.pathname : to;
    if (!escape) {
      return path;
    }
    let escapedPath;
    if (path) {
      if (Array.isArray(path)) {
        escapedPath = path.map(_path => escapePath(_path));
      } else {
        escapedPath = escapePath(path);
      }
    }
    return escapedPath;
  }, [escape, to]);
  const match = useRouteMatch({
    path: computedPath,
    exact
  });
  return match !== null;
}