import { CALYPSO, OZ, LORAX, SORBET, THUNDERDOME, MARIGOLD, NORMAN, CANDY_APPLE } from 'HubStyleTokens/colors';
export const FEATURED_CALLOUT_COLORS = {
  THUNDERDOME_TO_CALYPSO: [THUNDERDOME, CALYPSO],
  CALYPSO_TO_OZ: [CALYPSO, OZ],
  SORBET_TO_MARIGOLD: [SORBET, MARIGOLD],
  NORMAN_TO_CANDY_APPLE: [NORMAN, CANDY_APPLE],
  CANDY_APPLE_TO_LORAX: [CANDY_APPLE, LORAX],
  LORAX_TO_SORBET: [LORAX, SORBET]
};
export let FEATURED_CALLOUT_LAYOUT_TYPES;
(function (FEATURED_CALLOUT_LAYOUT_TYPES) {
  FEATURED_CALLOUT_LAYOUT_TYPES["FULL_WIDTH"] = "FULL_WIDTH";
  FEATURED_CALLOUT_LAYOUT_TYPES["MULTI"] = "MULTI";
})(FEATURED_CALLOUT_LAYOUT_TYPES || (FEATURED_CALLOUT_LAYOUT_TYPES = {}));