import quickFetch from 'quick-fetch';
export function getQuickFetch(requestName, newRelicAttribute) {
  const earlyRequest = quickFetch.getRequestStateByName(requestName);
  if (!earlyRequest) return Promise.reject(new Error(`No early request found: ${requestName}`));
  if (newRelicAttribute && window.newrelic) {
    window.newrelic.setCustomAttribute(newRelicAttribute, Boolean(earlyRequest.finished));
  }
  return new Promise((resolve, reject) => {
    earlyRequest.whenFinished(result => {
      quickFetch.removeEarlyRequest(requestName);
      resolve(result);
    });
    earlyRequest.onError(() => {
      quickFetch.removeEarlyRequest(requestName);
      reject(new Error(`Early request failed: ${requestName}`));
    });
  });
}