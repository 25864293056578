import { registerQuery } from 'data-fetching-client';
import { getModuleDetailsPreview } from '../../clients/ecosystemClient';
function getModuleDetailsPreviewData({
  slug
}) {
  return getModuleDetailsPreview(slug);
}
export const GET_MODULE_DETAILS_PREVIEW = registerQuery({
  fieldName: 'detailsPreview',
  args: ['slug'],
  fetcher: getModuleDetailsPreviewData
});