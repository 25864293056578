import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { pricingDefaults } from 'marketplace-ui-common/records/Pricing';
import { reviewSummaryDefaults } from 'marketplace-ui-reviews/records/ReviewSummary';
import { assetDetailsResponseProviderDefaults } from './AssetProviderDetailsResponse';
const assetOfferingDefaults = {
  homemade: false,
  installsCount: 0,
  firstPublishedAt: 0,
  slug: '',
  portalId: 0
};
export class AssetOffering extends createImmutableObjectClass(assetOfferingDefaults) {}
export const assetBaseDetailsResponseDefaults = {
  benefits: '',
  casesUrl: null,
  changelog: null,
  chatUrl: null,
  exampleUrl: '',
  facebookUrl: null,
  features: [],
  forumUrl: null,
  name: '',
  offering: assetOfferingDefaults,
  offeringId: 0,
  paypalEnabled: false,
  phone: null,
  pricing: pricingDefaults,
  privacy2Url: null,
  privacy3Url: null,
  privacyUrl: '',
  profileUrl: null,
  provider: assetDetailsResponseProviderDefaults,
  reviewSummary: reviewSummaryDefaults,
  screenshots: [],
  setupUrl: '',
  stripeEnabled: false,
  support: null,
  supportEmail: '',
  supportLanguages: [],
  supportUrl: null,
  tags: [],
  termsUrl: '',
  thumbnailUrl: '',
  tiers: [],
  verifiedAt: 0,
  versionNumber: 1,
  versionPublishedAt: 0,
  videoUrl: null,
  s3BucketKey: null
};