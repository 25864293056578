export const ABOUT = 'ABOUT';
export const BLOG_POST = 'BLOG_POST';
export const BLOG_POST_LISTING = 'BLOG_POST_LISTING';
export const CASE_STUDY = 'CASE_STUDY';
export const COMING_SOON = 'COMING_SOON';
export const CONFIRMATION = 'CONFIRMATION';
export const CONTACT_US = 'CONTACT_US';
export const EBOOK = 'EBOOK';
export const ERROR = 'ERROR';
export const EVENT = 'EVENT';
export const FAQ = 'FAQ';
export const HOMEPAGE = 'HOMEPAGE';
export const LANDING = 'LANDING';
export const LOGIN = 'LOGIN';
export const NEWSLETTER = 'NEWSLETTER';
export const OFFER = 'OFFER';
export const PILLAR = 'PILLAR';
export const PRICING = 'PRICING';
export const PRODUCT_LIBRARY = 'PRODUCT_LIBRARY';
export const REGISTRATION = 'REGISTRATION';
export const RESOURCES = 'RESOURCES';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';
export const SERVICES = 'SERVICES';
export const SINGLE_PRODUCT = 'SINGLE_PRODUCT';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const TEAM_MEMBERS = 'TEAM_MEMBERS';
export const TESTIMONIALS = 'TESTIMONIALS';
export const UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION';
export const WEBINAR = 'WEBINAR';