import { useCallback, useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { GET_COMPATIBILITY } from '../queries/compatibilityQuery';
import { getDataFetchingClientRequestState } from '../../dataFetchingClient/utils/getDataFetchingClientRequestState';
import * as TierGroups from '../../constants/groupTags/TierGroups';
export function useCompatibility({
  appContext,
  offeringType,
  scopeFeatures,
  tierGroupNames
}) {
  const {
    loading,
    error,
    data,
    previousData
  } = useQuery(GET_COMPATIBILITY, {
    variables: {
      appContext,
      offeringType
    }
  });
  const compatibilityData = useMemo(() => {
    const queryData = data || previousData;
    if (!queryData) {
      return {
        compatibility: {},
        portalTiers: []
      };
    } else {
      return queryData.compatibility;
    }
  }, [data, previousData]);
  const compatibilityRequestState = getDataFetchingClientRequestState(error, loading, !!compatibilityData);
  const getIsFeatureTierCompatible = useCallback((feature, tier) => feature.scopes.every(feature1 => {
    const requiredTiers = compatibilityData.compatibility[feature1];
    return !requiredTiers || !requiredTiers.length || requiredTiers.includes(tier);
  }), [compatibilityData.compatibility]);
  const featuresWithTierCompatibility = useMemo(() => {
    return scopeFeatures.map(feature => {
      return {
        text: feature.text,
        tiers: tierGroupNames.reduce((arr, tierGroup) => {
          TierGroups[tierGroup].forEach(tier => {
            if (getIsFeatureTierCompatible(feature, tier)) {
              arr.push(tier);
            }
          });
          return arr;
        }, [])
      };
    });
  }, [getIsFeatureTierCompatible, scopeFeatures, tierGroupNames]);
  return {
    compatibilityRequestState,
    featuresWithTierCompatibility,
    portalTiers: compatibilityData.portalTiers
  };
}