import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import * as AssetBusinessType from 'marketplace-ui-assets-core/constants/AssetBusinessType';
import { GET_UNUSED_FILTERS } from '../queries/unusedFiltersQuery';
export function useUsedBusinessTypeValues() {
  const {
    loading,
    data
  } = useQuery(GET_UNUSED_FILTERS);
  return useMemo(() => {
    const assetBusinessTypes = Object.values(AssetBusinessType);
    if (loading || !data) return assetBusinessTypes;
    return Object.values(AssetBusinessType).filter(value => !data.unusedFilters.unusedIndustries.includes(value));
  }, [loading, data]);
}