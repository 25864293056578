import AssetBusinessTypeUrls from '../../../constants/urls/AssetBusinessType';
import AssetPageFeatureUrls from '../../../constants/urls/AssetPageFeature';
import AssetPageTypeUrls from '../../../constants/urls/AssetPageType';
import ModuleCategoryTypeUrls from '../../../modules/urls/ModuleCategory';
const isBusinessTypeUrlValue = value => value in AssetBusinessTypeUrls;
const isPageTypeUrlValue = value => value in AssetPageTypeUrls;
const isPageFeatureUrlValue = value => value in AssetPageFeatureUrls;
const isModuleCategoryUrlValue = value => value in ModuleCategoryTypeUrls;
export function getQueryFieldUrl(field, value) {
  if (field === 'businessTypes' && isBusinessTypeUrlValue(value)) {
    return AssetBusinessTypeUrls[value];
  } else if (field === 'pageTypes' && isPageTypeUrlValue(value)) {
    return AssetPageTypeUrls[value];
  } else if (field === 'pageFeatures' && isPageFeatureUrlValue(value)) {
    return AssetPageFeatureUrls[value];
  } else if (field === 'categories' && isModuleCategoryUrlValue(value)) {
    return ModuleCategoryTypeUrls[value];
  } else return null;
}