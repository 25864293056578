import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { DEFAULT_ASSET_QUERY_LIMIT } from '../../common/constants/AssetQuery/AssetQuery';
const defaults = {
  businessTypes: [],
  hasFreeSupport: null,
  languages: [],
  limit: DEFAULT_ASSET_QUERY_LIMIT,
  minOverallRating: null,
  minReviewCount: null,
  offeringIds: [],
  offeringTypes: [],
  offset: 0,
  pageFeatures: [],
  pageTypes: [],
  planType: null,
  portalCompatible: null,
  priceRange: null,
  providers: [],
  search: null,
  sortFields: [],
  tags: [],
  tiers: []
};
export class ThemeQuery extends createImmutableObjectClass(defaults) {}