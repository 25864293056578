import { registerQuery } from 'data-fetching-client';
import * as ecosystemClient from '../clients/ecosystemClient';
function getProviders({
  offeringType
}) {
  return ecosystemClient.getProviders(offeringType);
}
export const GET_PROVIDERS = registerQuery({
  fieldName: 'providers',
  args: ['offeringType'],
  fetcher: getProviders
});