import { useLocation } from 'react-router-dom';
import getLang from 'I18n/utils/getLang';
import { getPaginatedPathname } from '../../utils/pagination';
import { getFullPublicUrl } from '../../utils/urls';
export function useCanonicalUrl(pageNumber) {
  const lang = getLang();
  let {
    pathname
  } = useLocation();
  if (pageNumber) {
    pathname = getPaginatedPathname(pathname, pageNumber);
  }
  return `${getFullPublicUrl(lang)}${pathname}`;
}