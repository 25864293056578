export const ACCORDION = 'ACCORDION';
export const APP_INTEGRATION = 'APP_INTEGRATION';
export const BANNER = 'BANNER';
export const BLOG_POST_LISTING = 'BLOG_POST_LISTING';
export const CALENDAR = 'CALENDAR';
export const CARD = 'CARD';
export const CAROUSEL = 'CAROUSEL';
export const COUNTDOWN = 'COUNTDOWN';
export const CTA = 'CTA';
export const EVENT = 'EVENT';
export const FEATURED_CONTENT = 'FEATURED_CONTENT';
export const FILTER = 'FILTER';
export const FOOTER = 'FOOTER';
export const FORM = 'FORM';
export const GALLERY = 'GALLERY';
export const HEADER = 'HEADER';
export const HERO = 'HERO';
export const IMAGE = 'IMAGE';
export const LOGO = 'LOGO';
export const MAP = 'MAP';
export const MENU = 'MENU';
export const NEWS = 'NEWS';
export const PAGINATION = 'PAGINATION';
export const PARALLAX = 'PARALLAX';
export const PRICING_TABLE = 'PRICING_TABLE';
export const PRODUCT_LIBRARY = 'PRODUCT_LIBRARY';
export const PROGRESS_BAR = 'PROGRESS_BAR';
export const SEARCH = 'SEARCH';
export const SLIDER = 'SLIDER';
export const SOCIAL = 'SOCIAL';
export const SUBMENU = 'SUBMENU';
export const SUBSCRIBE = 'SUBSCRIBE';
export const TABLE = 'TABLE';
export const TABS = 'TABS';
export const TEAM_MEMBER = 'TEAM_MEMBER';
export const TESTIMONIAL = 'TESTIMONIAL';
export const VIDEO = 'VIDEO';
export const WEATHER = 'WEATHER';