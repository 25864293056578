'use es6';

import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["app", "addMessageListener", "removeMessageListener"];
import { useContext, useEffect } from 'react';
import EmbedContext from '../context/EmbedContext';
let messageListenerCount = 0;
const getMessageListenerId = () => {
  const messageListenerId = `embed-message-listener-${messageListenerCount}`;
  messageListenerCount++;
  return messageListenerId;
};
export const useEmbedContext = messageListener => {
  const _useContext = useContext(EmbedContext),
    {
      app,
      addMessageListener,
      removeMessageListener
    } = _useContext,
    rest = _objectWithoutPropertiesLoose(_useContext, _excluded);
  useEffect(() => {
    if (messageListener) {
      const listenerId = getMessageListenerId();
      addMessageListener(listenerId, messageListener);
      return () => {
        removeMessageListener(listenerId, messageListener);
      };
    }
    return () => {};
  }, [addMessageListener, messageListener, removeMessageListener]);
  return Object.assign({}, rest, {
    app,
    isEmbedded: app != null
  });
};