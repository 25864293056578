export const APP_STARTED = 'APP_STARTED';
export const APP_CONTEXT_SET = 'APP_CONTEXT_SET';
export const INITIALIZE_AUTH = 'INITIALIZE_AUTH';
export const INITIALIZE_ADMIN_AUTH = 'INITIALIZE_ADMIN_AUTH';
export const PORTAL_SCOPES_REQUESTED = 'PORTAL_SCOPES_REQUESTED';
export const PORTAL_SCOPES_RECEIVED = 'PORTAL_SCOPES_RECEIVED';
export const PORTAL_SCOPES_FAILED = 'PORTAL_SCOPES_FAILED';
export const STOREFRONT_RESULTS_VIEW_UPDATED = 'STOREFRONT_RESULTS_VIEW_UPDATED';
export const REVIEW_FILTERS_INITIALIZED = 'REVIEW_FILTERS_INITIALIZED';
export const REVIEW_FILTERS_UPDATED = 'REVIEW_FILTERS_UPDATED';
export const THEME_DETAILS_SLUG_UPDATED = 'THEME_DETAILS_SLUG_UPDATED';
export const THEME_DETAILS_SLUG_RESET = 'THEME_DETAILS_SLUG_RESET';