import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import produce from 'immer';
import isEqual from 'hs-lodash/isEqual';
import omit from 'hs-lodash/omit';
import { uniqueId } from 'UIComponents/utils/underscore';
import * as PlanType from '../constants/pricings/PlanType';
export const pricingDefaults = {
  planType: PlanType.FREE,
  amount: 0,
  commitmentAmount: 0,
  cents: 0,
  commitmentCents: 0,
  extraCharges: '',
  freeTrialDays: 0,
  meterUnit: '',
  contactUsUrl: '',
  contactUsPlanType: null,
  contactUsDetails: null,
  name: '',
  tagline: '',
  features: '',
  updatedAt: null,
  id: ''
};
export default class Pricing extends createImmutableObjectClass(pricingDefaults, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return Object.assign({}, rest, {
      id: uniqueId('pricing-')
    });
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {
  // 'id' is used as a unique key that persists as the record updates, for ListTransition
  equals(pricing) {
    return isEqual(omit(this, ['id']), omit(pricing, ['id']));
  }
  updatePricing(updates) {
    return produce(this, pricingDraft => {
      Object.entries(updates).forEach(([key, value]) => {
        pricingDraft.set(key, value);
      });
    });
  }
}