import { registerQuery } from 'data-fetching-client';
// @ts-expect-error untyped dependency
import * as productMarketplaceClient from '../../clients/productMarketplaceClient';
function getAssetContents({
  bucketKey
}) {
  return productMarketplaceClient.getThemeContents(bucketKey);
}
export const GET_ASSET_CONTENTS = registerQuery({
  fieldName: 'contents',
  args: ['bucketKey'],
  fetcher: getAssetContents
});