import { useEffect } from 'react';
export function useFocusCloseButton(focused, closeButton) {
  useEffect(() => {
    if (focused) {
      const focusedElement = closeButton || document.querySelector('[role="button"][data-action="close"]');
      if (focusedElement) {
        focusedElement.focus();
      }
    }
  }, [focused, closeButton]);
}