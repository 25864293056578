import I18n from 'I18n';
import { ASSET_SORT_VALUES } from '../common/constants/AssetQuery/AssetQuery';
import * as AssetSort from '../common/constants/AssetSort';
export function useAssetSortOptions(isUnifiedSearch) {
  const assetSortOptions = ASSET_SORT_VALUES.map(sortValue => ({
    text: I18n.text(`marketplace-assets-ui.themes.sort.${sortValue}`),
    value: sortValue
  }));
  return isUnifiedSearch ? [{
    text: I18n.text(`marketplace-assets-ui.themes.sort.${AssetSort.RELEVANCE_DESC}`),
    value: AssetSort.RELEVANCE_DESC
  }, ...assetSortOptions] : assetSortOptions;
}