import { combineReducers } from 'redux';
import { THEME_DETAILS_SLUG_RESET, THEME_DETAILS_SLUG_UPDATED } from '../actions/ActionTypes';
function slug(state = null, action) {
  switch (action.type) {
    case THEME_DETAILS_SLUG_UPDATED:
      return action.payload;
    case THEME_DETAILS_SLUG_RESET:
      return null;
    default:
      return state;
  }
}
export default combineReducers({
  slug
});