'use es6';

import { useUsageTracker } from 'marketplace-ui-common/tracking/context/UsageTrackerContext';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
const UsageViewTracker = ({
  active = true,
  eventName = 'pageView',
  eventProperties = {},
  onTrackView
}) => {
  const tracker = useUsageTracker();
  const [tracked, setTracked] = useState(false);
  const getComputedEventProperties = useCallback(() => {
    return typeof eventProperties === 'function' ? eventProperties() : eventProperties;
  }, [eventProperties]);
  const trackView = useCallback(() => {
    if (onTrackView) {
      onTrackView(getComputedEventProperties());
    } else {
      tracker.track(eventName, getComputedEventProperties());
    }
  }, [tracker, eventName, getComputedEventProperties, onTrackView]);
  useEffect(() => {
    if (active && !tracked) {
      trackView();
      setTracked(true);
    }
  }, [active, trackView, tracked]);
  return null;
};
UsageViewTracker.propTypes = {
  active: PropTypes.bool,
  eventName: PropTypes.string,
  eventProperties: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onTrackView: PropTypes.func
};
export default UsageViewTracker;