import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import isEqual from 'hs-lodash/isEqual';
import omit from 'hs-lodash/omit';
import { uniqueId } from 'UIComponents/utils/underscore';
const clientExampleDefaults = {
  clientUrl: '',
  imageUrl: '',
  id: ''
};
export default class ClientExample extends createImmutableObjectClass(clientExampleDefaults, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return Object.assign({}, rest, {
      id: uniqueId('client-example-')
    });
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {
  // 'id' is used as a unique key that persists as the record updates, for ListTransition
  equals(clientExample) {
    return isEqual(omit(this, ['id']), omit(clientExample, ['id']));
  }
}