import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'data-fetching-client';
import getLang from 'I18n/utils/getLang';
import { getDataFetchingClientRequestState } from 'marketplace-ui-common/dataFetchingClient/utils/getDataFetchingClientRequestState';
import { GET_MODULE_CATEGORIES_QUERY } from '../queries/moduleCategoriesQuery';
import { getAppContext } from '../../selectors/app';
export function useModuleCategories() {
  const appContext = useSelector(getAppContext);
  const lang = getLang();
  const {
    loading,
    error,
    data,
    previousData
  } = useQuery(GET_MODULE_CATEGORIES_QUERY, {
    variables: {
      lang,
      appContext
    }
  });
  const requestState = getDataFetchingClientRequestState(error, loading);
  const moduleCategoriesData = useMemo(() => {
    const categoryTranslations = data || previousData;
    if (!categoryTranslations) {
      return [];
    } else {
      return categoryTranslations.moduleCategories.results;
    }
  }, [data, previousData]);
  const getCategoryDisplayName = useCallback(category => {
    if (requestState !== 'SUCCEEDED') {
      return category;
    }
    const displayNameTranslation = moduleCategoriesData.find(cat => cat.name === category);
    if (displayNameTranslation === undefined) {
      throw new Error('Category translation not found');
    }
    return displayNameTranslation.displayName;
  }, [moduleCategoriesData, requestState]);
  return {
    data: moduleCategoriesData,
    requestState,
    getCategoryDisplayName
  };
}