import { registerQuery } from 'data-fetching-client';
import { getThemeDetailsPreview } from '../../clients/ecosystemClient';
function getThemeDetailsPreviewData({
  slug
}) {
  return getThemeDetailsPreview(slug);
}
export const GET_THEME_DETAILS_PREVIEW = registerQuery({
  fieldName: 'detailsPreview',
  args: ['slug'],
  fetcher: getThemeDetailsPreviewData
});