export const HUBSPOT_FREE = 'HUBSPOT_FREE';
export const CMS_STARTER = 'CMS_STARTER';
export const CMS_STANDALONE = 'CMS_STANDALONE';
export const CMS_ENTERPRISE = 'CMS_ENTERPRISE';
export const CONTENT_STARTER = 'CONTENT_STARTER';
export const CONTENT_PROFESSIONAL = 'CONTENT_PROFESSIONAL';
export const CONTENT_ENTERPRISE = 'CONTENT_ENTERPRISE';
export const MARKETING_FREE = 'MARKETING_FREE';
export const MARKETING_STARTER = 'MARKETING_STARTER';
export const MARKETING_PROFESSIONAL = 'MARKETING_PROFESSIONAL';
export const MARKETING_ENTERPRISE = 'MARKETING_ENTERPRISE';
export const OPS_STARTER = 'OPS_STARTER';
export const OPS_PROFESSIONAL = 'OPS_PROFESSIONAL';
export const OPS_ENTERPRISE = 'OPS_ENTERPRISE';
export const SALES_FREE = 'SALES_FREE';
export const SALES_STARTER = 'SALES_STARTER';
export const SALES_PROFESSIONAL = 'SALES_PROFESSIONAL';
export const SALES_ENTERPRISE = 'SALES_ENTERPRISE';
export const SERVICE_FREE = 'SERVICE_FREE';
export const SERVICE_STARTER = 'SERVICE_STARTER';
export const SERVICE_PROFESSIONAL = 'SERVICE_PROFESSIONAL';
export const SERVICE_ENTERPRISE = 'SERVICE_ENTERPRISE';