import produce from 'immer';
import { parse as parseParams } from 'hub-http/helpers/params';
import * as AssetBusinessType from 'marketplace-ui-assets-core/constants/AssetBusinessType';
import * as AssetPageFeature from 'marketplace-ui-assets-core/constants/AssetPageFeature';
import * as AssetPageType from 'marketplace-ui-assets-core/constants/AssetPageType';
import * as Tiers from 'marketplace-ui-common/constants/groupTags/Tiers';
import * as AssetSort from '../../constants/AssetSort';
import filterIncludes from '../../../utils/filterIncludes';
import includes from '../../../utils/includes';
import replaceAll from '../../../utils/replaceAll';
import { DEFAULT_ASSET_QUERY_LIMIT } from '../../constants/AssetQuery/AssetQuery';
import { MODULE_CONTENT_TYPE_VALUES } from '../../../modules/constants/ModuleContentType';
import { ModuleQuery } from '../../../modules/records/ModuleQuery';
import { ThemeQuery } from '../../../themes/records/ThemeQuery';
import { getOffsetFromPageNumber } from '../../../utils/pagination';
import { isParsedThemesRouteParams } from '../isAssetType';
export function getComputedQuery(offeringType, parsedParams, location) {
  const {
    pageNumber
  } = parsedParams;
  const {
    search
  } = location;
  const offset = getOffsetFromPageNumber(pageNumber, DEFAULT_ASSET_QUERY_LIMIT);
  let queryObj = getAssetQueryFromLocation(search);
  queryObj = produce(queryObj, draft => {
    draft.offset = offset;
    if (isParsedThemesRouteParams(parsedParams)) {
      const {
        businessType,
        pageFeature,
        pageType
      } = parsedParams;
      if (businessType) draft.businessTypes = [businessType];
      if (pageType) draft.pageTypes = [pageType];
      if (pageFeature) draft.pageFeatures = [pageFeature];
    } else {
      const {
        category
      } = parsedParams;
      if (category) draft.categories = [category];
    }
  });
  if (queryObj.search) {
    if (queryObj.offeringTypes && queryObj.offeringTypes.length === 1 && queryObj.offeringTypes[0] === 'MODULE') {
      return new ModuleQuery(queryObj);
    } else {
      return new ThemeQuery(queryObj);
    }
  }
  return offeringType === 'MODULE' ? new ModuleQuery(queryObj) : new ThemeQuery(queryObj);
}
function toArray(param) {
  return Array.isArray(param) ? param : [param];
}
function getAssetQueryFromLocation(search) {
  const params = parseParams(replaceAll(search.slice(1), '+', '%20'));
  const queryObj = Object.keys(params).reduce((record, paramKey) => {
    if (!/^eco_/i.test(paramKey)) {
      return record;
    }
    const paramValue = params[paramKey];
    const unprefixedParamKey = paramKey.replace(/^eco_/i, '');
    parseQueryValue(record, unprefixedParamKey, paramValue);
    return record;
  }, {});
  return queryObj;
}
const ASSET_BUSINESS_TYPE_VALUES = Object.values(AssetBusinessType);
const ASSET_OFFERING_TYPE_VALUES = ['MODULE', 'THEME'];
const ASSET_PAGE_FEATURE_VALUES = Object.values(AssetPageFeature);
const ASSET_PAGE_TYPE_VALUES = Object.values(AssetPageType);
const ASSET_SORT_VALUES = Object.values(AssetSort);
const TIER_TYPE_VALUES = Object.values(Tiers);
function parseQueryValue(record, key, value) {
  switch (key) {
    case 'businessTypes':
      record.businessTypes = filterIncludes(toArray(value), ASSET_BUSINESS_TYPE_VALUES);
      break;
    case 'pageTypes':
      record.pageTypes = filterIncludes(toArray(value), ASSET_PAGE_TYPE_VALUES);
      break;
    case 'pageFeatures':
      record.pageFeatures = filterIncludes(toArray(value), ASSET_PAGE_FEATURE_VALUES);
      break;
    case 'categories':
      record[key] = toArray(value);
      break;
    case 'contentTypes':
      record.contentTypes = filterIncludes(toArray(value), MODULE_CONTENT_TYPE_VALUES);
      break;
    case 'hasFreeSupport':
    case 'portalCompatible':
      record[key] = value === 'true' ? true : null;
      break;
    case 'tags':
    case 'providers':
      record[key] = toArray(value);
      break;
    case 'offeringIds':
      record.offeringIds = toArray(value).reduce((ids, id) => {
        const parsed = parseInt(id, 10);
        if (isNaN(parsed)) return ids;
        return ids.concat([parsed]);
      }, []);
      break;
    case 'offeringTypes':
      record.offeringTypes = filterIncludes(toArray(value), ASSET_OFFERING_TYPE_VALUES);
      break;
    case 'planType':
      record.priceRange = null;
      record.planType = includes(['ONE_TIME', 'FREE'], value) ? value : null;
      break;
    case 'sortFields':
      record.sortFields = filterIncludes(toArray(value), ASSET_SORT_VALUES);
      break;
    case 'tiers':
      record.tiers = filterIncludes(toArray(value), TIER_TYPE_VALUES);
      break;
    case 'minOverallRating':
      {
        const parsedQueryValue = parseInt(value, 10);
        record.minOverallRating = !isNaN(parsedQueryValue) && parsedQueryValue > 0 && parsedQueryValue < 5 ? parsedQueryValue : null;
        break;
      }
    case 'priceRangeMin':
    case 'priceRangeMax':
      {
        if (record.planType) return;
        const priceRangeKey = key === 'priceRangeMin' ? 'minCents' : 'maxCents';
        record.priceRange = Object.assign({}, record.priceRange || {
          minCents: null,
          maxCents: null
        }, {
          [priceRangeKey]: parseInt(value, 10)
        });
        break;
      }
    case 'search':
      record.search = value;
      break;
    default:
      break;
  }
}