import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["limit", "offset", "search"];
import { isThemeQuery } from '../isAssetType';
export function toUsageEventProperties(query) {
  const eventProperties = Object.assign({
    minOverallRating: query.minOverallRating,
    paymentType: query.planType,
    search: query.search,
    searchLength: query.search ? query.search.length : undefined,
    sortField: query.sortFields,
    support: query.hasFreeSupport,
    tags: query.tags
  }, isThemeQuery(query) && {
    industry: query.businessTypes,
    pageType: query.pageTypes,
    feature: query.pageFeatures
  });
  Object.keys(eventProperties).forEach(_key => {
    const key = _key;
    const values = eventProperties[key];
    if (values == null || Array.isArray(values) && !values.length) {
      delete eventProperties[key];
    }
  });
  const restQueryObj = _objectWithoutPropertiesLoose(query, _excluded);
  eventProperties.allFilters = getDeepFilterEventPropertyValues(restQueryObj);
  return eventProperties;
}
function getDeepFilterEventPropertyValues(obj, prefix = '') {
  return Object.keys(obj).reduce((final, key) => {
    const value = obj[key];
    if (value != null && value !== '') {
      const updatedPrefix = `${prefix ? `${prefix}::` : ''}${key}`;
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (typeof item === 'object' && !Array.isArray(item)) {
            final = final.concat(getDeepFilterEventPropertyValues(item, `${updatedPrefix}::${index}`));
          } else {
            final.push(`${updatedPrefix}::${item}`);
          }
        });
      } else if (typeof value === 'object') {
        final = final.concat(getDeepFilterEventPropertyValues(value, updatedPrefix));
      } else {
        final.push(`${updatedPrefix}::${value}`);
      }
    }
    return final;
  }, []);
}