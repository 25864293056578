const ModuleCategoryTypeUrls = {
  BLOG: 'blog',
  BODY_CONTENT: 'body-content',
  COMMERCE: 'commerce',
  DESIGN: 'design',
  FUNCTIONALITY: 'functionality',
  FORMS_AND_BUTTONS: 'form-and-button',
  MEDIA: 'media',
  SOCIAL: 'social',
  TEXT: 'text'
};
export default ModuleCategoryTypeUrls;