import * as AssetSort from '../../../common/constants/AssetSort';
import { DEFAULT_ASSET_SORT_VALUE } from '../../constants/AssetQuery/AssetQuery';
export function toSearchQuery(query, offeringType, isUnifiedSearch) {
  const isCustomRange = query.priceRange !== null;
  return Object.entries(query).reduce((searchQuery, [key_, value]) => {
    const key = key_;
    if (key === 'sortFields') {
      if (value.length && value[0] === AssetSort.RANKING_ASC) {
        searchQuery.minReviewCount = 3;
      } else if (!value.length && searchQuery.search) {
        value = [AssetSort.RELEVANCE_DESC];
      } else if (!value.length) {
        value = [DEFAULT_ASSET_SORT_VALUE];
      }
    } else if (key === 'offeringTypes' && !value.length) {
      if (isUnifiedSearch) {
        value = null;
      } else {
        value = {
          clause: 'OR',
          values: [offeringType]
        };
      }
    } else if (key === 'planType' && isCustomRange) {
      value = undefined;
    } else if (key === 'minOverallRating' && !!value) {
      searchQuery.minReviewCount = 3;
    } else if (Array.isArray(value) && value.length) {
      if (key !== 'offeringIds') {
        value = {
          clause: 'OR',
          values: value
        };
      }
    } else if (Array.isArray(value)) {
      value = undefined;
    }
    if (value != null) {
      searchQuery[key_] = value;
    }
    return searchQuery;
  }, {});
}