const AssetPageTypeUrls = {
  ABOUT: 'about',
  BLOG_POST: 'blog',
  BLOG_POST_LISTING: 'listing',
  // CASE_STUDY
  COMING_SOON: 'coming-soon',
  CONFIRMATION: 'confirmation',
  CONTACT_US: 'contact-us',
  // EBOOK
  ERROR: 'error',
  EVENT: 'event',
  FAQ: 'faq',
  HOMEPAGE: 'home',
  LANDING: 'landing',
  LOGIN: 'login',
  OFFER: 'offer',
  // NEWSLETTER
  PILLAR: 'pillar',
  PRICING: 'pricing',
  PRODUCT_LIBRARY: 'product-listing',
  REGISTRATION: 'registration',
  RESOURCES: 'resource',
  SEARCH_RESULTS: 'search-results',
  SERVICES: 'service',
  SINGLE_PRODUCT: 'product',
  SUBSCRIPTION: 'subscription',
  // TEAM_MEMBERS
  TESTIMONIALS: 'testimonial',
  UNDER_CONSTRUCTION: 'under-construction',
  WEBINAR: 'webinar'
};
export default AssetPageTypeUrls;