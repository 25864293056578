import { useEffect, useRef } from 'react';

/* Somehow the best way to do this in 2023.
 * tl;dr: forwardedRef might be null, or a function, so define your own ref and reassign .current manually
 * Source: https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
 */
export function useForwardedRef(forwardedRef) {
  const innerRef = useRef(null);
  useEffect(() => {
    if (!forwardedRef) return;
    if (typeof forwardedRef === 'function') {
      forwardedRef(innerRef.current);
    } else {
      forwardedRef.current = innerRef.current;
    }
  });
  return innerRef;
}