import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import * as AssetPageType from 'marketplace-ui-assets-core/constants/AssetPageType';
import { GET_UNUSED_FILTERS } from '../queries/unusedFiltersQuery';
export function useUsedPageTypeValues() {
  const {
    loading,
    data
  } = useQuery(GET_UNUSED_FILTERS);
  return useMemo(() => {
    const assetPageTypes = Object.values(AssetPageType);
    if (loading || !data) return assetPageTypes;
    return Object.values(AssetPageType).filter(value => !data.unusedFilters.unusedPageTypes.includes(value));
  }, [loading, data]);
}