'use es6';

import { reportZorseUndefined } from './sentry';

/* globals hubspot */

export const openHelpWidget = options => {
  if (hubspot && hubspot.zorse && hubspot.zorse.openHelpWidget) {
    hubspot.zorse.openHelpWidget(options);
  } else {
    reportZorseUndefined(options);
  }
};