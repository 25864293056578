const AssetBusinessTypeUrls = {
  ART_ENTERTAINMENT: 'entertainment',
  CUSTOMER_SERVICE: 'customer',
  ECOMMERCE: 'ecommerce',
  EVENT_MANAGEMENT: 'event-management',
  FINANCIAL_SERVICES: 'finance',
  FOOD_BEVERAGE: 'food',
  HEALTH_BEAUTY: 'health',
  HR_RECRUITING: 'hr',
  MARKETING: 'marketing',
  NONPROFIT_EDUCATION: 'nonprofit',
  PROFESSIONAL_SERVICES: 'services',
  REAL_ESTATE: 'real-estate',
  RETAIL: 'retail',
  SMALL_BUSINESS: 'small-business',
  TECHNOLOGY: 'technology',
  TRAVEL_HOSPITALITY: 'travel'
};
export default AssetBusinessTypeUrls;