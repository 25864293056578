import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { GET_PROVIDERS } from '../queries/providerFilterQuery';
export function useProviders(offeringType) {
  const {
    loading,
    data
  } = useQuery(GET_PROVIDERS, {
    variables: {
      offeringType
    }
  });
  return useMemo(() => {
    if (loading || !data) return [];
    return data.providers.providerNames;
  }, [loading, data]);
}