import { useEffect } from 'react';

/**
 * Keep focus within current modal when open
 * Select modal and modal's focasable elements
 * If shift key pressed for shift + tab completion, focus last focusable element
 * Else if tab key is pressed, focus first focusable element
 */
export function useFocusTrap(open, elementRef) {
  useEffect(() => {
    if (open) {
      const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]):not(span)';
      const modal = elementRef || document.querySelector('[data-component-name="UIPanel"]');
      if (modal) {
        const focusableContent = modal.querySelectorAll(focusableElements);
        const firstFocusableElement = focusableContent[0];
        const lastFocusableElement = focusableContent[focusableContent.length - 1];
        document.addEventListener('keydown', e => {
          const isTabPressed = e.key === 'Tab' || e.code === 'Tab';
          if (!isTabPressed) {
            return;
          }
          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              lastFocusableElement.focus();
              e.preventDefault();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              firstFocusableElement.focus();
              e.preventDefault();
            }
          }
        });
      }
    }
  }, [open, elementRef]);
}