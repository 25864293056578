import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["versionHistory"],
  _excluded2 = ["versionHistory"];
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
import VersionInfo from './VersionInfo';
const versionHistoryResponseDefaults = {
  versionHistory: [],
  total: 0
};
export default class VersionHistoryResponse extends createImmutableObjectClass(versionHistoryResponseDefaults, {
  fromJSON: _ref => {
    let {
        versionHistory
      } = _ref,
      rest = _objectWithoutPropertiesLoose(_ref, _excluded);
    return cleanObject(Object.assign({
      versionHistory: versionHistory.map(version => new VersionInfo(version))
    }, rest));
  },
  toJSON: _ref2 => {
    let {
        versionHistory
      } = _ref2,
      rest = _objectWithoutPropertiesLoose(_ref2, _excluded2);
    return Object.assign({
      versionHistory: versionHistory.map(version => version.toJSON())
    }, rest);
  }
}) {}