'use es6';

import { APP_STARTED, APP_CONTEXT_SET } from './ActionTypes';
export const startApp = () => dispatch => {
  dispatch({
    type: APP_STARTED
  });
};
export const setAppContext = appContext => dispatch => {
  dispatch({
    type: APP_CONTEXT_SET,
    payload: appContext
  });
};