import { useMemo } from 'react';
import I18n from 'I18n';
import * as AssetPageFeature from 'marketplace-ui-assets-core/constants/AssetPageFeature';
import { useStorefrontRouteParamTranslations } from './useStorefrontRouteParamTranslations';
import { useModulesStorefrontDocumentTitle } from './useStorefrontDocumentTitle';
const year = `${new Date().getFullYear()}`;
export function useThemesStorefrontMetaDescription({
  description: passedDescription,
  routeParams
}) {
  const {
    businessType,
    pageType,
    pageFeature
  } = routeParams;
  const translatedRouteParams = useStorefrontRouteParamTranslations(routeParams, 'lower');
  const content = useMemo(() => {
    if (passedDescription) {
      return passedDescription;
    }
    const {
      businessType: businessTypeText,
      pageType: pageTypeText,
      pageFeature: pageFeatureText
    } = translatedRouteParams;
    if (businessType && pageType) {
      return I18n.text('marketplace-assets-ui.themes.metaDescription.businessTypePageType', {
        businessType: I18n.SafeString(businessTypeText),
        pageType: I18n.SafeString(pageTypeText),
        year
      });
    }
    if (businessType && pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.businessTypePageFeature.APP_INTEGRATION', {
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.businessTypePageFeature.PARALLAX', {
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.businessTypePageFeature.WEATHER', {
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.businessTypePageFeature.defaultMetaDescription', {
            businessType: I18n.SafeString(businessTypeText),
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    if (pageType && pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageTypePageFeature.APP_INTEGRATION', {
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageTypePageFeature.PARALLAX', {
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageTypePageFeature.WEATHER', {
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageTypePageFeature.defaultMetaDescription', {
            pageType: I18n.SafeString(pageTypeText),
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    if (businessType) {
      return I18n.text('marketplace-assets-ui.themes.metaDescription.businessType', {
        businessType: I18n.SafeString(businessTypeText),
        year
      });
    }
    if (pageType) {
      return I18n.text('marketplace-assets-ui.themes.metaDescription.pageType', {
        pageType: I18n.SafeString(pageTypeText),
        year
      });
    }
    if (pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageFeature.APP_INTEGRATION', {
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageFeature.PARALLAX', {
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageFeature.WEATHER', {
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.metaDescription.pageFeature.defaultMetaDescription', {
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    return I18n.text('marketplace-assets-ui.themes.metaDescription.defaultMetaDescription');
  }, [businessType, pageFeature, pageType, passedDescription, translatedRouteParams]);
  return content;
}
export function useModulesStorefrontMetaDescription({
  description: passedDescription,
  resultsCount,
  routeParams,
  title,
  providerCount
}) {
  const documentTitle = useModulesStorefrontDocumentTitle({
    routeParams,
    total: resultsCount,
    title,
    simplified: true
  });
  const content = useMemo(() => {
    if (passedDescription) {
      return passedDescription;
    }
    return I18n.text('marketplace-assets-ui.modules.metaDescription.defaultMetaDescription', {
      documentTitle,
      providerCount,
      year
    });
  }, [passedDescription, documentTitle, providerCount]);
  return content;
}
export default function useStorefrontMetaDescription(offeringType) {
  return offeringType === 'MODULE' ? useModulesStorefrontMetaDescription : useThemesStorefrontMetaDescription;
}