import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import { GET_FEATURED_CALLOUTS_QUERY } from '../queries/featuredCalloutsQuery';
import { getDataFetchingClientRequestState } from '../../dataFetchingClient/utils/getDataFetchingClientRequestState';
export function useFeaturedCallouts(props) {
  const {
    loading,
    error,
    data,
    previousData
  } = useQuery(GET_FEATURED_CALLOUTS_QUERY, {
    variables: props
  });
  const requestState = getDataFetchingClientRequestState(error, loading);
  const featuredCalloutsData = useMemo(() => {
    const featuredCallouts = data || previousData;
    if (!featuredCallouts) {
      return [];
    } else {
      return featuredCallouts.featuredCallouts.slice(0, 2);
    }
  }, [data, previousData]);
  return {
    data: featuredCalloutsData,
    requestState
  };
}