const AssetPageFeatureUrls = {
  ACCORDION: 'accordion',
  APP_INTEGRATION: 'plugin',
  BANNER: 'banner',
  // 'BLOG_POST_LISTING'
  CALENDAR: 'calendar',
  // 'CARD'
  CAROUSEL: 'carousel',
  COUNTDOWN: 'countdown',
  // 'CTA'
  // 'EVENT'
  // 'FEATURED_CONTENT'
  // 'FILTER'
  FOOTER: 'footer',
  FORM: 'form',
  GALLERY: 'gallery',
  HEADER: 'header',
  // 'HERO'
  IMAGE: 'image',
  LOGO: 'logo',
  MAP: 'map',
  MENU: 'menu',
  NEWS: 'news',
  PAGINATION: 'pagination',
  PARALLAX: 'parallax',
  PRICING_TABLE: 'pricing-table',
  // 'PRODUCT_LIBRARY'
  // 'PROGRESS_BAR'
  // 'SEARCH'
  SLIDER: 'slider',
  SOCIAL: 'social',
  // 'SUBMENU'
  // 'SUBSCRIBE'
  TABLE: 'table',
  // 'TABS'
  // 'TEAM_MEMBER'
  // 'TESTIMONIAL'
  VIDEO: 'video',
  WEATHER: 'weather'
};
export default AssetPageFeatureUrls;