export const MISSING_MARKETPLACE_ASSET_ACCESS = 'MISSING_MARKETPLACE_ASSET_ACCESS';
export const MISSING_HUB_SCOPES = 'MISSING_HUB_SCOPES';
export const MISSING_REQUIRED_SUBSCRIPTION = 'MISSING_REQUIRED_SUBSCRIPTION';
export const ALREADY_INSTALLED = 'ALREADY_INSTALLED';
export const EXCEEDS_ASSET_LIMIT = 'EXCEEDS_ASSET_LIMIT';
export const PURCHASED_NOT_INSTALLED = 'PURCHASED_NOT_INSTALLED';
export const PROVIDER_OFFERING = 'PROVIDER_OFFERING';
export const PENDING_PAYMENT = 'PENDING_PAYMENT';
export const PENDING_INSTALL = 'PENDING_INSTALL';
export const PENDING_UNINSTALL = 'PENDING_UNINSTALL';
export const FORBIDDEN_ADMIN_PREVIEW = 'FORBIDDEN_ADMIN_PREVIEW';
export const FORBIDDEN_PUBLIC = 'FORBIDDEN_PUBLIC';