import { registerQuery } from 'data-fetching-client';
import { ecosystemAppClient } from 'marketplace-ui-common/clients/ecosystemClient';
export const ASSET_INSTALLABILITY_QUERY_FIELD_NAME = 'assetInstallability';
export const GET_ASSET_INSTALLABILITY = registerQuery({
  fieldName: ASSET_INSTALLABILITY_QUERY_FIELD_NAME,
  args: ['offeringId'],
  fetcher: ({
    offeringId
  }) => {
    return ecosystemAppClient.get(`assets/${offeringId}/installability`);
  }
});