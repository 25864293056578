// https://timleland.com/money-in-javascript/
export const formatDollarsToCents = _value => {
  let value = `${_value}`.replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }
  return value ? Math.round(parseFloat(value) * 100) : 0;
};
export const formatCentsToDollars = _value => {
  const value = parseFloat(`${_value}`.replace(/[^\d.-]/g, ''));
  return value ? value / 100 : 0;
};