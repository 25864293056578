// @ts-expect-error dependency missing types
import CRM_ICON from 'bender-url!../../img/CRM.svg';

// New as of Jan 14 2022
// @ts-expect-error dependency missing types
import JAN_2022_MARKETING_ICON from 'bender-url!../../img/MarketingHub_Icon.svg';
// @ts-expect-error dependency missing types
import JAN_2022_SALES_ICON from 'bender-url!../../img/SalesHub_Icon.svg';
// @ts-expect-error dependency missing types
import JAN_2022_SERVICE_ICON from 'bender-url!../../img/ServiceHub_Icon.svg';
// @ts-expect-error dependency missing types
import CONTENT_ICON from 'bender-url!../../img/ContentHub_Icon.svg';
// @ts-expect-error dependency missing types
import JAN_2022_OPERATIONS_ICON from 'bender-url!../../img/OperationsHub_Icon.svg';
// @ts-expect-error dependency missing types
import COMMERCE_ICON from 'bender-url!../../img/CommerceHub_Icon.svg';
import * as EnrolledProductLines from 'self-service-api/constants/EnrolledProductLines';
import Hubs from 'self-service-api/constants/Hubs';
import * as ProductLines from 'self-service-api/constants/ProductLines';
export const CRM = CRM_ICON;
export const ProductLinePageIcons = {
  [ProductLines.MARKETING]: JAN_2022_MARKETING_ICON,
  [ProductLines.SALES]: JAN_2022_SALES_ICON,
  [ProductLines.SERVICE]: JAN_2022_SERVICE_ICON,
  [ProductLines.CMS]: CONTENT_ICON,
  [ProductLines.OPERATIONS]: JAN_2022_OPERATIONS_ICON,
  [EnrolledProductLines.COMMERCE]: COMMERCE_ICON
};
export const getProductLineIcons = () => ProductLinePageIcons;
export default {
  [Hubs.HUB_MARKETING]: JAN_2022_MARKETING_ICON,
  [Hubs.HUB_SALES]: JAN_2022_SALES_ICON,
  [Hubs.HUB_SERVICE]: JAN_2022_SERVICE_ICON,
  [Hubs.HUB_CMS]: CONTENT_ICON,
  [Hubs.HUB_OPERATIONS]: JAN_2022_OPERATIONS_ICON,
  [EnrolledProductLines.COMMERCE]: COMMERCE_ICON,
  // This is a legacy Icon as of Jan 2022. We should remove references to it and then delete.
  crm: CRM_ICON
};