import { useMemo } from 'react';
import unescapedText from 'I18n/utils/unescapedText';
import { getAssetBusinessTypeI18nKey, getAssetPageTypeI18nKey, getAssetPageFeatureI18nKey } from 'marketplace-ui-assets-core/utils/i18n';
export function useStorefrontRouteParamTranslations(routeParams, casing = 'sentence') {
  const {
    businessType,
    pageType,
    pageFeature
  } = routeParams;
  const result = useMemo(() => {
    const isSentenceCase = casing === 'sentence';
    const _result = {};
    if (businessType) {
      _result.businessType = unescapedText(getAssetBusinessTypeI18nKey(businessType, isSentenceCase));
    }
    if (pageType) {
      _result.pageType = unescapedText(getAssetPageTypeI18nKey(pageType, isSentenceCase));
    }
    if (pageFeature) {
      _result.pageFeature = unescapedText(getAssetPageFeatureI18nKey(pageFeature, isSentenceCase));
    }
    if (casing === 'lower') {
      Object.keys(_result).forEach(key => {
        _result[key] = _result[key].toLocaleLowerCase();
      });
    }
    return _result;
  }, [businessType, casing, pageFeature, pageType]);
  return result;
}