export function isThemeQuery(query) {
  if (query.offeringTypes.length === 1) {
    return query.offeringTypes.includes('THEME');
  }
  return 'businessTypes' in query;
}
export function isRawThemesRouteParams(params) {
  return 'businessType' in params || 'pageType' in params || 'pageFeature' in params;
}
export function isParsedThemesRouteParams(params) {
  return 'businessType' in params || 'pageType' in params || 'pageFeature' in params;
}