import { generatePath } from 'react-router-dom';
import AssetBusinessTypeUrls from '../../../constants/urls/AssetBusinessType';
import AssetPageFeatureUrls from '../../../constants/urls/AssetPageFeature';
import AssetPageTypeUrls from '../../../constants/urls/AssetPageType';
import ModuleCategoryTypeUrls from '../../../modules/urls/ModuleCategory';
import { getPageNumberFromOffset, getPaginatedPathname } from '../../../utils/pagination';
import { getModulesStorefrontRoute, getTemplatesSearchRoute, getThemesStorefrontRoute } from '../../../utils/urls';
import { DEFAULT_ASSET_QUERY_LIMIT } from '../../constants/AssetQuery/AssetQuery';
import { isRawThemesRouteParams, isThemeQuery } from '../isAssetType';
import { toQueryString } from './toQueryString';
const MAX_PATH_PARAMS = 2;
export function getLocationFromQuery(query, currentLocation, isCollection = false) {
  const {
    pathname: currentPathname
  } = currentLocation;
  let pathPattern;
  let pathParams = {};
  if (isCollection) {
    pathPattern = currentPathname;
  } else {
    pathParams = getRouteParamsFromQuery(query);
    pathPattern = toPathPattern(pathParams);
    if (query.search) {
      pathPattern = getTemplatesSearchRoute();
    } else {
      pathPattern = isThemeQuery(query) ? getThemesStorefrontRoute(pathPattern) : getModulesStorefrontRoute(pathPattern);
    }
  }
  const pathname = generatePath(pathPattern, pathParams);
  const pageNumber = getPageNumberFromOffset(query.offset, DEFAULT_ASSET_QUERY_LIMIT);
  const queryString = toQueryString(query, pathParams);
  return {
    pathname: getPaginatedPathname(pathname, pageNumber),
    search: queryString ? `?${queryString}` : ''
  };
}
const ThemesPathPartsSortOrder = ['businessType', 'pageType', 'pageFeature'];
function compareThemesRouteParams(a, b) {
  return ThemesPathPartsSortOrder.indexOf(a) - ThemesPathPartsSortOrder.indexOf(b);
}
function toPathPattern(params) {
  const pathParts = isRawThemesRouteParams(params) ? Object.keys(params).sort(compareThemesRouteParams) : Object.keys(params);
  if (pathParts.length && pathParts.length <= MAX_PATH_PARAMS) {
    return `/${pathParts.map(part => `:${part}`).join('/')}`;
  } else {
    return '/';
  }
}
function toRouteParam(urls, values) {
  if (values) {
    if (values.length !== 1) return null;
    const [value] = values;
    return value in urls ? urls[value] : null;
  }
  return null;
}
function getRouteParamsFromQuery(query) {
  if (isThemeQuery(query)) {
    const {
      businessTypes,
      pageTypes,
      pageFeatures
    } = query;
    const businessType = toRouteParam(AssetBusinessTypeUrls, businessTypes);
    const pageType = toRouteParam(AssetPageTypeUrls, pageTypes);
    const pageFeature = toRouteParam(AssetPageFeatureUrls, pageFeatures);
    if (businessType && pageType && pageFeature) return {};
    return Object.assign({}, businessType && {
      businessType
    }, pageType && {
      pageType
    }, pageFeature && {
      pageFeature
    });
  } else {
    const {
      categories
    } = query;
    const category = toRouteParam(ModuleCategoryTypeUrls, categories);
    return Object.assign({}, category && {
      category
    });
  }
}