import arrayUnion from './arrayUnion';

/**
 * This function takes an object and returns a union type of literal types,
 * where each literal type corresponds to a value in the object.
 *
 * @param obj - An object. The values of this object will be used to create the union type.
 * @returns A union type of literal types. Each literal type corresponds to a value in the input object.
 */

export default function valueUnion(obj) {
  return arrayUnion(Object.values(obj));
}