import { getFullUrl } from 'hubspot-url-utils';
import { na1 } from 'hubspot-url-utils/hublets';
import I18n from 'I18n';
import getLang from 'I18n/utils/getLang';
import PortalIdParser from 'PortalIdParser';
import { Flow } from 'signup-constants/Flow';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
const getRootPath = () => '/marketplace';
export const getEcosystemOriginUrl = () => getFullUrl('ecosystem');
export const getRootUrl = () => {
  return `/ecosystem/${PortalIdParser.get()}${getRootPath()}`;
};
export const getPublicRootUrl = lang => {
  const publicRoot = getRootPath();
  switch (lang) {
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'ja':
    case 'nl':
    case 'pt':
    case 'zh':
    case 'no':
    case 'fi':
    case 'da':
    case 'pl':
    case 'sv':
      return `/${lang}${publicRoot}`;
    default:
      return publicRoot;
  }
};
export const getPublicMKPLUrl = () => `${getEcosystemOriginUrl()}/marketplace`;
export const getEmbedRootUrl = () => `/ecosystem/${PortalIdParser.get()}/marketplace-embed/assets`;
export const getTemplatesHomePageRoute = () => '/templates';
export const getThemesStorefrontRoute = (path = '') => `/website/${path.replace(/^\/?/, '')}`.replace(/\/$/, '');
export const getThemeDetailsRoute = slug => `/website/${slug}`;
export const getThemeCollectionPreviewRoute = collectionId => `/preview/assets/themes/collections/${collectionId}`;
export const getAllThemeCollectionsRoute = () => '/templates/featured';
export const getTemplatesSearchRoute = () => '/templates/search';
export const getModulesStorefrontRoute = (path = '') => `/modules/${path.replace(/^\/?/, '')}`.replace(/\/$/, '');
export const getStorefrontRoute = (offeringType, path = '') => {
  switch (offeringType) {
    case 'MODULE':
      return getModulesStorefrontRoute(path);
    case 'THEME':
    default:
      return getThemesStorefrontRoute(path);
  }
};
export const getModuleDetailsRoute = slug => `/modules/${slug}`;
export const getModuleDetailsReviewRoute = slug => `/modules/${slug}/write-review`;
export const getPublicModuleDetailPageFullUrl = (lang, slug) => {
  return `${getFullUrl('ecosystem', {
    hubletOverride: 'na1'
  })}${getPublicRootUrl(lang)}/modules/${slug}`;
};
export const getPublicThemeDetailPageFullUrl = (lang, slug) => {
  return `${getFullUrl('ecosystem', {
    hubletOverride: 'na1'
  })}${getPublicRootUrl(lang)}/website/${slug}`;
};
export const getDetailsRoute = (offeringType, slug) => {
  switch (offeringType) {
    case 'MODULE':
      return getModuleDetailsRoute(slug);
    case 'THEME':
      return getThemeDetailsRoute(slug);
    default:
      return getThemeDetailsRoute(slug);
  }
};
export const getPublicDetailPageFullUrl = (lang, offeringType, slug) => {
  switch (offeringType) {
    case 'MODULE':
      return getPublicModuleDetailPageFullUrl(lang, slug);
    case 'THEME':
      return getPublicThemeDetailPageFullUrl(lang, slug);
    default:
      return getPublicThemeDetailPageFullUrl(lang, slug);
  }
};
export const getThemeDetailPageFullUrl = slug => {
  return `${getFullUrl('app', {
    hubletOverride: na1
  })}/ecosystem/${PortalIdParser.get()}/marketplace/website/${slug}`;
};
export const getModuleDetailPageFullUrl = slug => {
  return `${getFullUrl('app', {
    hubletOverride: na1
  })}/ecosystem/${PortalIdParser.get()}/marketplace/modules/${slug}`;
};
export const getTemplatesRoute = slug => `/templates/${slug}`;
export const getDetailPageFullUrl = (offeringType, slug) => {
  switch (offeringType) {
    case 'MODULE':
      return getModuleDetailPageFullUrl(slug);
    case 'THEME':
      return getThemeDetailPageFullUrl(slug);
    default:
      return getThemeDetailPageFullUrl(slug);
  }
};
const getPublicLangUrl = langUrlMap => {
  // @ts-expect-error untyped dependency
  const lang = I18n.loaded ? getLang() : 'en';
  return lang in langUrlMap ? langUrlMap[lang] : langUrlMap.en;
};
export const getCmsHubProductUrl = () => getPublicLangUrl({
  en: 'https://www.hubspot.com/products/cms',
  de: 'https://www.hubspot.de/products/cms',
  es: 'https://www.hubspot.es/products/cms',
  fr: 'https://www.hubspot.fr/products/cms',
  ja: 'https://www.hubspot.jp/products/cms',
  pt: 'https://br.hubspot.com/products/cms'
});
export const getAssetProviderSignupUrl = () => {
  return new SignupUrlGenerator(Flow.AssetProvider).getUrl();
};
export const getFullPublicUrl = (lang, useLocal) => {
  return `${getFullUrl(useLocal ? 'local' : 'ecosystem', {
    hubletOverride: 'na1'
  })}${getPublicRootUrl(lang)}`;
};
export const getFullPublicThemesUrl = (lang, useLocal) => {
  return `${getFullPublicUrl(lang, useLocal)}/website`;
};
export const getFullPublicModulesUrl = (lang, useLocal) => {
  return `${getFullPublicUrl(lang, useLocal)}/modules`;
};
export const getFullSocialImageUrl = (lang, path) => {
  return `${getFullUrl('api', {
    hubletOverride: 'na1'
  })}/ecosystem/public/v1/screenshots/assets/${lang}/marketplace${path.replace(/\/$/, '')}.png`;
};
export const getFullTemplatesPublicUrl = (lang, path = '') => {
  return `${getFullUrl('ecosystem', {
    hubletOverride: 'na1'
  })}${getPublicRootUrl(lang)}/templates${path}`;
};
export const getPublicAssetProviderSignupUrl = () => 'https://offers.hubspot.com/free-marketplace-account';