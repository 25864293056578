import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
const defaults = {
  categories: [],
  contentTypes: [],
  fileType: 'RAW_ASSET',
  label: '',
  path: '',
  scopes: [],
  screenshotUrl: '',
  templateType: ''
};
export default class ThemeContentItem extends createImmutableObjectClass(defaults, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return cleanObject(Object.assign({}, rest));
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {}