'use es6';

import { combineReducers } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
import { PAGE_KEY } from 'marketplace-ui-reviews/constants/ReviewsListQueries';
import * as ReviewTypes from 'marketplace-ui-reviews/constants/ReviewType';
import ReviewFilters from 'marketplace-ui-reviews/records/ReviewFilters';
const filters = (state = new ReviewFilters(), {
  type,
  payload
}) => {
  switch (type) {
    case ActionTypes.REVIEW_FILTERS_INITIALIZED:
      return new ReviewFilters({
        reviewTypes: [ReviewTypes.THEME],
        entityId: payload.entityId
      });
    case ActionTypes.REVIEW_FILTERS_UPDATED:
      {
        let newFilters = state.updateFilters(payload.updates);
        if (!payload.updates[PAGE_KEY]) {
          newFilters = newFilters.updateFilters({
            [PAGE_KEY]: 1
          });
        }
        return newFilters;
      }
    default:
      return state;
  }
};
export default combineReducers({
  filters
});