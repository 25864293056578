import * as t from 'ts-schema';

/**
 * This function takes an array of values and returns a union type of literal types.
 * The `t.literal` function is used to create a literal type for each item in the array.
 *
 * @param arr - An array of values. Each value can be of any type.
 * @returns A union type of literal types. Each literal type corresponds to an item in the input array.
 */
export default function arrayUnion(arr) {
  return t.union(...arr.map(item => t.literal(item)));
}