import produce from 'immer';
import { ThemeQuery } from '../../../themes/records/ThemeQuery';
import { ModuleQuery } from '../../../modules/records/ModuleQuery';
import { isThemeQuery } from '../isAssetType';
import { ASSET_QUERY_FILTER_ORDER } from '../../constants/AssetQuery/AssetQuery';
export function clearQueryFilters(query) {
  let updatedQuery;
  if (isThemeQuery(query)) {
    updatedQuery = clearThemeQueryFilters(query);
  } else {
    updatedQuery = clearModuleQueryFilters(query);
  }
  updatedQuery = produce(updatedQuery, draft => {
    for (const field of ASSET_QUERY_FILTER_ORDER) {
      delete draft[field];
    }
    draft.offset = 0;
  });
  return isThemeQuery(query) ? new ThemeQuery(updatedQuery) : new ModuleQuery(updatedQuery);
}
export function clearOfferingQueryFilters(query) {
  if (!query.offeringTypes.length) {
    return new ThemeQuery(clearThemeQueryFilters(query));
  }
  return isThemeQuery(query) ? new ThemeQuery(query) : new ModuleQuery(query);
}
function clearThemeQueryFilters(query) {
  return produce(query, draft => {
    delete draft.businessTypes;
    delete draft.pageTypes;
    delete draft.pageFeatures;
  });
}
function clearModuleQueryFilters(query) {
  return produce(query, draft => {
    delete draft.categories;
    delete draft.contentTypes;
  });
}