import { registerQuery } from 'data-fetching-client';
import { getThemeDetails } from '../../clients/ecosystemClient';
function getThemeDetailsData({
  slug
}) {
  return getThemeDetails(slug);
}
export const GET_THEME_DETAILS = registerQuery({
  fieldName: 'details',
  args: ['slug'],
  fetcher: getThemeDetailsData
});