export const ART_ENTERTAINMENT = 'ART_ENTERTAINMENT';
export const CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';
export const ECOMMERCE = 'ECOMMERCE';
export const EVENT_MANAGEMENT = 'EVENT_MANAGEMENT';
export const FINANCIAL_SERVICES = 'FINANCIAL_SERVICES';
export const FOOD_BEVERAGE = 'FOOD_BEVERAGE';
export const HEALTH_BEAUTY = 'HEALTH_BEAUTY';
export const HR_RECRUITING = 'HR_RECRUITING';
export const MARKETING = 'MARKETING';
export const NONPROFIT_EDUCATION = 'NONPROFIT_EDUCATION';
export const PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES';
export const REAL_ESTATE = 'REAL_ESTATE';
export const RETAIL = 'RETAIL';
export const SMALL_BUSINESS = 'SMALL_BUSINESS';
export const TECHNOLOGY = 'TECHNOLOGY';
export const TRAVEL_HOSPITALITY = 'TRAVEL_HOSPITALITY';