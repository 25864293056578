export function getPageNumberFromOffset(offset, limit) {
  return Math.floor(offset / limit) + 1;
}
export function getOffsetFromPageNumber(pageNumber, limit) {
  return (pageNumber - 1) * limit;
}
export function getPaginatedPathname(pathname, pageNumber = 1) {
  return pathname.replace(/(\/?(page\/\d+)?)$/, pageNumber > 1 ? `/page/${pageNumber}` : '');
}
export function getPaginatedRoute(route) {
  return `${route}/page/:pageNumber([1-9][0-9]*)`;
}