import { useCallback } from 'react';
import { registerQuery, useDataFetchingClient
// useLazyQuery,
} from 'data-fetching-client';
import { getThemeCollectionItems, getThemeCollectionPreviewItems } from '../../clients/qualityClient';
export const GET_THEME_COLLECTION_OFFERING_IDS = registerQuery({
  fieldName: 'themeCollectionOfferingIds',
  args: ['collectionId', 'adminPreview'],
  fetcher({
    collectionId,
    adminPreview
  }) {
    const request = adminPreview ? getThemeCollectionPreviewItems(collectionId) : getThemeCollectionItems(collectionId);
    return request.then(({
      items
    }) => items.map(({
      offeringId
    }) => offeringId));
  }
});
export function useFetchThemeCollectionOfferingIds({
  collectionId,
  adminPreview = false
}) {
  const client = useDataFetchingClient();
  const fetchThemeCollectionOfferingIds = useCallback(() => {
    if (!collectionId) {
      return Promise.resolve([]);
    }
    return client.query({
      query: GET_THEME_COLLECTION_OFFERING_IDS,
      variables: {
        collectionId,
        adminPreview
      }
    }).then(({
      data
    }) => data.themeCollectionOfferingIds);
  }, [adminPreview, client, collectionId]);

  // TODO test once @apollo/client is upgraded to latest
  // Issue: https://git.hubteam.com/HubSpot/Frontend/issues/1668
  // const [fetchThemeCollectionOfferingIds] = useLazyQuery(
  //   GET_THEME_COLLECTION_ITEMS,
  //   {
  //     variables: {
  //       collectionId,
  //     },
  //   }
  // );

  return {
    fetchThemeCollectionOfferingIds
  };
}