import { registerQuery } from 'data-fetching-client';
import * as ecosystemClient from '../clients/ecosystemClient';
function getSearchTags({
  offeringType
}) {
  return ecosystemClient.getUnifiedSearchTags(offeringType);
}
export const GET_SEARCH_TAGS = registerQuery({
  fieldName: 'searchTags',
  args: ['offeringType'],
  fetcher: getSearchTags
});