import { useEffect } from 'react';
import { requestIdleCallback } from 'marketplace-ui-common/utils/requestIdleCallback';
import { appendHubsParams } from '../utils/hubsParams';
export default function HubsParamsTracker() {
  useEffect(() => {
    const cancelCallback = requestIdleCallback(() => {
      appendHubsParams();
    });
    return () => cancelCallback();
  }, []);
  return null;
}