'use es6';

import { combineReducers } from 'redux';
import * as ActionTypes from '../actions/ActionTypes';
const resultsView = (state = 'list', action) => {
  switch (action.type) {
    case ActionTypes.STOREFRONT_RESULTS_VIEW_UPDATED:
      return action.payload;
    default:
      return state;
  }
};
export default combineReducers({
  resultsView
});