const MAX_INSTALLS_BUCKET = 10000;
const getValueFactor = (value, factor) => {
  return Math.floor(value / factor) * factor;
};
export const getInstallsBucket = value => {
  if (value < 10) {
    return 0;
  } else if (value < 50) {
    return getValueFactor(value, 10);
  } else if (value < 100) {
    return getValueFactor(value, 50);
  } else if (value < 500) {
    return getValueFactor(value, 100);
  } else if (value < MAX_INSTALLS_BUCKET) {
    return getValueFactor(value, 500);
  }
  return MAX_INSTALLS_BUCKET;
};