'use es6';

import * as ActionTypes from '../actions/ActionTypes';
export const initialState = {
  gates: [],
  portal: {
    scopes: []
  },
  user: {
    scopes: []
  }
};
const auth = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INITIALIZE_AUTH:
      return {
        user: action.user,
        gates: action.gates,
        portal: Object.assign({}, state.portal, action.portal)
      };
    case ActionTypes.INITIALIZE_ADMIN_AUTH:
      return Object.assign({}, state, {
        user: action.user
      });
    case ActionTypes.PORTAL_SCOPES_RECEIVED:
      {
        return Object.assign({}, state, {
          portal: Object.assign({}, state.portal, {
            scopes: [...state.portal.scopes, ...action.payload]
          })
        });
      }
    default:
      return state;
  }
};
export default auth;