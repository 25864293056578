'use es6';

import { createSelector } from 'reselect';
import { debugGate } from '../utils/env';
const getGates = state => state.auth.gates;
const getPortal = state => state.auth.portal;
const getUser = state => state.auth.user;
export const selectPortalScopes = state => state.auth.portal.scopes;
export const selectUserScopes = state => state.auth.user.scopes;
export const getAuth = state => state.auth;
const getIsUngated = createSelector([getGates], gates => gate => gates.includes(gate) || debugGate(gate));
export const getIsCustomer = createSelector([getPortal], portal => !!(portal && portal.portal_id));
export const getUserFirstName = createSelector([getUser], user => user.first_name);
export const getUserLastName = createSelector([getUser], user => user.last_name);
export const getUserEmail = createSelector([getUser], user => user.email);
export const getUserId = createSelector([getUser], user => user.user_id);
export const getHasZorseWebticketScope = createSelector([selectUserScopes], scopes => scopes.includes('zorse-webticket'));
export const getIsUngatedForModuleContentTypes = createSelector([getIsUngated], isUngated => isUngated('Marketplace:Templates:ModuleContentTypes'));