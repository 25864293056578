import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// @ts-expect-error module is untyped
import { selectPortalScopes } from '../selectors/auth';
export function useMissingPortalScopes(requiredScopes) {
  const portalScopes = useSelector(selectPortalScopes);
  const missingPortalScopes = useMemo(() => {
    if (!requiredScopes) return [];
    return requiredScopes.filter(scope => !portalScopes.includes(scope));
  }, [requiredScopes, portalScopes]);
  return missingPortalScopes;
}