'use es6';

import PortalIdParser from 'PortalIdParser';
import { parse as parseParams } from 'hub-http/helpers/params';
import userInfo from 'hub-http/userInfo';
import adminUserInfo from 'hubspotter-http/hubSpotterInfo';
import * as hubsClient from '../clients/hubsClient';
import * as ActionTypes from './ActionTypes';
export const getAuth = () => dispatch => {
  let {
    _gated: gated,
    _ungated: ungated
  } = parseParams(window.location.search.slice(1));
  if (gated) {
    gated = Array.isArray(gated) ? gated : [gated];
  }
  if (ungated) {
    ungated = Array.isArray(ungated) ? ungated : [ungated];
  }
  PortalIdParser.get();
  return userInfo().then(({
    user,
    gates,
    portal
  }) => {
    let gatesWithOverrides = [...gates];
    if (gated) {
      gatesWithOverrides = gatesWithOverrides.filter(gate => !gated.includes(gate));
    }
    if (ungated) {
      gatesWithOverrides = gatesWithOverrides.concat(ungated.filter(gate => !gatesWithOverrides.includes(gate)));
    }
    dispatch({
      type: ActionTypes.INITIALIZE_AUTH,
      user,
      gates: gatesWithOverrides,
      portal
    });
    return {
      user,
      gates,
      portal
    };
  });
};
export const getAdminAuth = () => dispatch => {
  return adminUserInfo().then(({
    hubspotter: user
  }) => {
    dispatch({
      type: ActionTypes.INITIALIZE_ADMIN_AUTH,
      user
    });
  });
};
export const getPortalScopes = scopes => dispatch => {
  dispatch({
    type: ActionTypes.PORTAL_SCOPES_REQUESTED,
    meta: {
      scopes
    }
  });
  return hubsClient.getPortalScopes(scopes).then(({
    scopes: portalScopes
  }) => {
    dispatch({
      type: ActionTypes.PORTAL_SCOPES_RECEIVED,
      payload: portalScopes,
      meta: {
        scopes
      }
    });
    return portalScopes;
  }).catch(error => {
    dispatch({
      type: ActionTypes.PORTAL_SCOPES_FAILED,
      payload: error,
      meta: {
        scopes
      }
    });
    throw error;
  });
};