import { registerQuery } from 'data-fetching-client';
import { getModuleDetails } from '../../clients/ecosystemClient';
function getModuleDetailsData({
  slug
}) {
  return getModuleDetails(slug);
}
export const GET_MODULE_DETAILS = registerQuery({
  fieldName: 'details',
  args: ['slug'],
  fetcher: getModuleDetailsData
});