import enviro from 'enviro';
const KEY_SEPARATOR = '::';
const SERVICE_NAME = 'marketplace-assets';
const GATE_PREFIX = 'gate';
const getDebugKey = key => `${SERVICE_NAME}${key ? `${KEY_SEPARATOR}${key}` : ''}`;
export const debug = key => enviro.debug(getDebugKey(key)) === 'true';
export const setDebugKey = (key, value) => enviro.setDebug(getDebugKey(key), value);
const getGateDebugKey = gate => [GATE_PREFIX, gate].join(KEY_SEPARATOR);
export const debugGate = gate => debug(getGateDebugKey(gate));
export const setDebugGate = (gate, value) => setDebugKey(getGateDebugKey(gate), value);
export const deployed = () => enviro.deployed(SERVICE_NAME);
export const isQa = () => enviro.isQa(SERVICE_NAME);
export const isProd = () => enviro.isProd(SERVICE_NAME);