import { registerQuery } from 'data-fetching-client';
import { getRelevantFeaturedCalloutIds, getFeaturedCallouts } from '../../clients/qualityClient';
export const GET_FEATURED_CALLOUTS_QUERY = registerQuery({
  fieldName: 'featuredCallouts',
  args: ['appContext', 'offeringType'],
  fetcher: props => {
    if (props.appContext === 'admin-preview') {
      return getFeaturedCallouts(props);
    }
    return Promise.all([getRelevantFeaturedCalloutIds(props), getFeaturedCallouts(props)]).then(([relevantIds, callouts]) => {
      const relevantIdsSet = new Set(relevantIds.calloutIds);
      const relevantCallouts = callouts.filter(callout => relevantIdsSet.has(callout.id));
      return relevantCallouts;
    });
  }
});