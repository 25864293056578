import { combineReducers } from 'redux';
import { APP_CONTEXT_SET } from '../actions/ActionTypes';
import * as AppContexts from '../constants/AppContext';
function appContext(state = AppContexts.IN_APP, action) {
  switch (action.type) {
    case APP_CONTEXT_SET:
      return action.payload;
    default:
      return state;
  }
}
export default combineReducers({
  appContext
});