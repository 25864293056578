'use es6';

import * as ActionTypes from './ActionTypes';
export const initializeReviewFilters = entityId => ({
  type: ActionTypes.REVIEW_FILTERS_INITIALIZED,
  payload: {
    entityId
  }
});
export const updateReviewFilters = (updates, reviewTypes = ['THEME']) => ({
  type: ActionTypes.REVIEW_FILTERS_UPDATED,
  payload: {
    updates,
    reviewTypes
  }
});