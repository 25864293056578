import _objectDestructuringEmpty from "@babel/runtime/helpers/esm/objectDestructuringEmpty";
import { createImmutableObjectClass } from 'immutable-less/ImmutableObject';
import { cleanObject } from 'marketplace-ui-common/utils/formatters';
export const versionInfoDefaults = {
  versionNumber: 0,
  publishedAt: 0,
  releaseNotes: ''
};
export default class VersionInfo extends createImmutableObjectClass(versionInfoDefaults, {
  fromJSON: _ref => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
    return cleanObject(Object.assign({}, rest));
  },
  toJSON: _ref2 => {
    let rest = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
    return Object.assign({}, rest);
  }
}) {}