import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import * as RequestState from 'marketplace-ui-common/requestStates/RequestState';
import { GET_ASSET_INSTALLABILITY } from '../queries/assetInstallabilityQuery';
import * as AssetUninstallableReason from '../constants/AssetUninstallableReason';
export function useAssetInstallability({
  appContext,
  offeringId,
  portalScopes,
  skip = false,
  userScopes
}) {
  const userHasMarketplaceAssetAccess = userScopes.includes('marketplace-asset-access');
  const portalHasRequiredSubscription = portalScopes.includes('global-content-write-access');
  const isAdminPreviewApp = appContext === 'admin-preview';
  const isPublicApp = appContext === 'public';
  const {
    loading,
    error,
    data
  } = useQuery(GET_ASSET_INSTALLABILITY, {
    skip: skip || isAdminPreviewApp || isPublicApp,
    variables: {
      offeringId
    }
  });
  const requestState = useMemo(() => {
    if (isAdminPreviewApp || isPublicApp) {
      return RequestState.SUCCEEDED;
    }
    if (loading) {
      return RequestState.PENDING;
    }
    if (data) {
      return RequestState.SUCCEEDED;
    }
    if (error) {
      return RequestState.FAILED;
    }
    return RequestState.UNINITIALIZED;
  }, [data, error, isAdminPreviewApp, isPublicApp, loading]);
  const assetUninstallableReason = useMemo(() => {
    if (isAdminPreviewApp) {
      return AssetUninstallableReason.FORBIDDEN_ADMIN_PREVIEW;
    } else if (isPublicApp) {
      return AssetUninstallableReason.FORBIDDEN_PUBLIC;
    }
    if (!data) {
      return null;
    }
    const {
      assetInstallability: {
        exceedsAssetLimit,
        installPending,
        installed,
        pendingPaymentExpiry,
        purchased,
        selfMade,
        uninstallPending
      }
    } = data;
    if (!userHasMarketplaceAssetAccess) {
      return AssetUninstallableReason.MISSING_MARKETPLACE_ASSET_ACCESS;
    } else if (installed) {
      return AssetUninstallableReason.ALREADY_INSTALLED;
    } else if (!portalHasRequiredSubscription) {
      return AssetUninstallableReason.MISSING_REQUIRED_SUBSCRIPTION;
    } else if (exceedsAssetLimit) {
      return AssetUninstallableReason.EXCEEDS_ASSET_LIMIT;
    } else if (purchased && !installed) {
      return AssetUninstallableReason.PURCHASED_NOT_INSTALLED;
    } else if (pendingPaymentExpiry) {
      return AssetUninstallableReason.PENDING_PAYMENT;
    } else if (installPending) {
      return AssetUninstallableReason.PENDING_INSTALL;
    } else if (uninstallPending) {
      return AssetUninstallableReason.PENDING_UNINSTALL;
    } else if (selfMade) {
      return AssetUninstallableReason.PROVIDER_OFFERING;
    }
    return null;
  }, [data, userHasMarketplaceAssetAccess, portalHasRequiredSubscription, isAdminPreviewApp, isPublicApp]);
  const result = useMemo(() => {
    return {
      assetUninstallableReason,
      installability: data ? data.assetInstallability : null,
      requestState
    };
  }, [assetUninstallableReason, data, requestState]);
  return result;
}