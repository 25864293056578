import { registerQuery } from 'data-fetching-client';
import * as t from 'ts-schema';
import * as Tier from '../../constants/groupTags/Tiers';
import { getCompatibility } from '../../clients/ecosystemClient';
import valueUnion from '../../schemas/valueUnion';
const compatibilityResponseSchema = t.object({
  compatibility: t.object({}),
  portalTiers: t.array(valueUnion(Tier))
});
export const GET_COMPATIBILITY = registerQuery({
  fieldName: 'compatibility',
  args: ['appContext', 'offeringType'],
  fetcher({
    appContext,
    offeringType
  }) {
    return getCompatibility({
      appContext,
      offeringType
    }).then(resp => t.assert(compatibilityResponseSchema, {
      portalTiers: resp.portalTiers || [],
      // ensure that portalTiers is not undefined
      compatibility: resp.compatibility
    }));
  }
});