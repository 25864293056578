import { useMemo } from 'react';
import I18n from 'I18n';
import * as AssetPageFeature from 'marketplace-ui-assets-core/constants/AssetPageFeature';
import { useStorefrontRouteParamTranslations } from './useStorefrontRouteParamTranslations';
import { useModuleCategories } from '../../modules/hooks/useModuleCategories';
const year = `${new Date().getFullYear()}`;
export function useThemesStorefrontDocumentTitle({
  routeParams,
  title: passedTitle,
  total
}) {
  const {
    businessType,
    pageType,
    pageFeature
  } = routeParams;
  const translatedRouteParams = useStorefrontRouteParamTranslations(routeParams, 'title');
  const documentTitle = useMemo(() => {
    const {
      businessType: businessTypeText,
      pageType: pageTypeText,
      pageFeature: pageFeatureText
    } = translatedRouteParams;
    if (passedTitle) {
      return passedTitle;
    }
    if (businessType && pageType) {
      return I18n.text('marketplace-assets-ui.themes.documentTitle.businessTypePageType', {
        count: total,
        businessType: I18n.SafeString(businessTypeText),
        pageType: I18n.SafeString(pageTypeText),
        year
      });
    }
    if (businessType && pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.businessTypePageFeature.APP_INTEGRATION', {
            count: total,
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.businessTypePageFeature.PARALLAX', {
            count: total,
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.businessTypePageFeature.WEATHER', {
            count: total,
            businessType: I18n.SafeString(businessTypeText),
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.businessTypePageFeature.defaultTitle', {
            count: total,
            businessType: I18n.SafeString(businessTypeText),
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    if (pageType && pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageTypePageFeature.APP_INTEGRATION', {
            count: total,
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageTypePageFeature.PARALLAX', {
            count: total,
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageTypePageFeature.WEATHER', {
            count: total,
            pageType: I18n.SafeString(pageTypeText),
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageTypePageFeature.defaultTitle', {
            count: total,
            pageType: I18n.SafeString(pageTypeText),
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    if (businessType) {
      return I18n.text('marketplace-assets-ui.themes.documentTitle.businessType', {
        count: total,
        businessType: I18n.SafeString(businessTypeText),
        year
      });
    }
    if (pageType) {
      return I18n.text('marketplace-assets-ui.themes.documentTitle.pageType', {
        count: total,
        pageType: I18n.SafeString(pageTypeText),
        year
      });
    }
    if (pageFeature) {
      switch (pageFeature) {
        case AssetPageFeature.APP_INTEGRATION:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageFeature.APP_INTEGRATION', {
            count: total,
            year
          });
        case AssetPageFeature.PARALLAX:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageFeature.PARALLAX', {
            count: total,
            year
          });
        case AssetPageFeature.WEATHER:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageFeature.WEATHER', {
            count: total,
            year
          });
        default:
          return I18n.text('marketplace-assets-ui.themes.documentTitle.pageFeature.defaultTitle', {
            count: total,
            pageFeature: I18n.SafeString(pageFeatureText),
            year
          });
      }
    }
    return I18n.text('marketplace-assets-ui.themes.documentTitle.defaultTitle', {
      count: total,
      year
    });
  }, [businessType, pageFeature, pageType, passedTitle, total, translatedRouteParams]);
  return documentTitle;
}
export function useModulesStorefrontDocumentTitle({
  routeParams,
  title: passedTitle,
  total,
  simplified = false
}) {
  const {
    category
  } = routeParams;
  const {
    getCategoryDisplayName
  } = useModuleCategories();
  const documentTitle = useMemo(() => {
    if (passedTitle) {
      return passedTitle;
    } else if (category) {
      try {
        return I18n.text('marketplace-assets-ui.modules.documentTitle.category', {
          count: total,
          category: getCategoryDisplayName(category),
          year
        });
      } catch (_unused) {
        /* do nothing */
      }
    }
    if (simplified) {
      return I18n.text('marketplace-assets-ui.modules.documentTitle.simplifiedTitle', {
        count: total,
        year
      });
    }
    return I18n.text('marketplace-assets-ui.modules.documentTitle.defaultTitle', {
      count: total,
      year
    });
  }, [category, getCategoryDisplayName, passedTitle, simplified, total]);
  return documentTitle;
}
export default function useStorefrontDocumentTitle(offeringType) {
  return offeringType === 'MODULE' ? useModulesStorefrontDocumentTitle : useThemesStorefrontDocumentTitle;
}