import { registerQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
const getVersionHistory = ({
  offeringId,
  offset,
  limit
}) => http.get('ecosystem/v1/offerings/versions/history', {
  query: {
    offeringId,
    offset,
    limit
  }
});
export const registerVersionHistoryQuery = fetcher => registerQuery({
  fieldName: 'versionHistory',
  args: ['offeringId', 'offset', 'limit'],
  fetcher: fetcher || getVersionHistory
});
export const GET_VERSION_HISTORY = registerVersionHistoryQuery();