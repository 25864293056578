'use es6';

import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
export const getPortalScopes = scopes => {
  return http.get(`hubs2/v1/scopes/hub/${PortalIdParser.get()}/filter`, {
    query: {
      scopes
    }
  });
};