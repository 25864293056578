import { registerQuery } from 'data-fetching-client';
import { getRelevantCollectionIds, getCollections } from '../../clients/qualityClient';
export const GET_COLLECTIONS_QUERY = registerQuery({
  fieldName: 'collections',
  args: ['appContext', 'offeringType'],
  fetcher: props => {
    if (props.appContext === 'admin-preview') {
      return getCollections(props);
    }
    return Promise.all([getRelevantCollectionIds(props), getCollections(props)]).then(([relevantIds, collections]) => {
      const relevantIdsSet = new Set(relevantIds.collectionIds);
      const relevantCollections = collections.collections.filter(collection => relevantIdsSet.has(collection.id));
      return {
        collections: relevantCollections
      };
    });
  }
});