import { useMemo } from 'react';
import { useQuery } from 'data-fetching-client';
import * as AssetPageFeature from 'marketplace-ui-assets-core/constants/AssetPageFeature';
import { GET_UNUSED_FILTERS } from '../queries/unusedFiltersQuery';
export function useUsedPageFeatureValues() {
  const {
    loading,
    data
  } = useQuery(GET_UNUSED_FILTERS);
  return useMemo(() => {
    const assetPageFeatures = Object.values(AssetPageFeature);
    if (loading || !data) return assetPageFeatures;
    return Object.values(AssetPageFeature).filter(value => !data.unusedFilters.unusedPageFeatures.includes(value));
  }, [loading, data]);
}