import * as t from 'ts-schema';
import { registerQuery } from 'data-fetching-client';
import { getApiClient } from '../../clients/apiClient';
import { getQuickFetch } from '../../clients/ecosystemClient';
import * as AppContext from '../../constants/AppContext';
export const moduleCategoriesResponseSchema = t.object({
  results: t.array(t.object({
    id: t.number,
    name: t.string,
    displayName: t.string
  }))
});
export const GET_MODULE_CATEGORIES_QUERY = registerQuery({
  fieldName: 'moduleCategories',
  args: ['lang', 'appContext'],
  fetcher: ({
    lang,
    appContext
  }) => {
    return getQuickFetch('module-categories').catch(() => {
      return getApiClient().get(appContext === AppContext.PUBLIC ? 'content/categories/v1/categories/meta/no-auth' : 'content/categories/v1/categories/meta', {
        query: {
          lang
        }
      });
    });
  }
});