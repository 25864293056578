import { getApiClient } from './apiClient';
let qualityApiBase = 'marketplace/quality/customer/v1';
const qualityAdminApiBase = 'marketplace/quality/v1';
export const setQualityApiBase = base => {
  qualityApiBase = base;
};
export function getThemeCollectionItems(collectionId) {
  return getApiClient().get(`${qualityApiBase}/self-serve/themes/collections/${collectionId}/items`);
}
export function getThemeCollectionPreviewItems(collectionId) {
  return getApiClient().get(`${qualityAdminApiBase}/self-serve/themes/collections/${collectionId}/items`);
}