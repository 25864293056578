import { stringify } from 'hub-http/helpers/params';
import { isRawThemesRouteParams } from '../isAssetType';
const QUERY_STRING_DISALLOW_LIST = ['offeringIds', 'limit', 'offset'];
export function toQueryString(query, pathParams) {
  const validEntries = Object.entries(query).filter(([key, value]) => {
    const isKeyAllowed = !QUERY_STRING_DISALLOW_LIST.includes(key);
    const hasValue = Array.isArray(value) ? !!value.length : value !== null && value !== '';
    return isKeyAllowed && hasValue;
  });
  const queryObj = Object.fromEntries(validEntries);
  if (pathParams && isRawThemesRouteParams(pathParams)) {
    if (pathParams.businessType) delete queryObj.businessTypes;
    if (pathParams.pageType) delete queryObj.pageTypes;
    if (pathParams.pageFeature) delete queryObj.pageFeatures;
  } else if (pathParams) {
    if (pathParams.category) delete queryObj.categories;
  }
  if ('priceRange' in queryObj) {
    if (!('planType' in queryObj)) {
      const {
        minCents,
        maxCents
      } = queryObj.priceRange;
      queryObj.priceRangeMin = minCents;
      queryObj.priceRangeMax = maxCents;
    }
    delete queryObj.priceRange;
  }
  if (query.search === null && query.offeringTypes.length > 0) {
    delete queryObj.offeringTypes;
  }
  const prefixedQueryObj = Object.keys(queryObj).reduce((result, key) => Object.assign({}, result, {
    [`eco_${key}`]: queryObj[key]
  }), {});
  return stringify(prefixedQueryObj);
}